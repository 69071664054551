(function () {
    angular.module('TodoTurnosApp')
        .controller('reservarTurnoResumenController', ['$routeParams', '$scope', 'TodoTurnosAppSession', 'srvcPrestadores', '$location', 'TodoTurnosAppSession', 'srvcSeguridad', 'srvcPacientes',

            function ($routeParams, $scope, TodoTurnosAppSession, srvcPrestadores, $location, session, srvcSeguridad, srvcPacientes) {
                $scope.idInstitucion = $routeParams.idInstitucion;
                $scope.idPrestador = $routeParams.idPrestador;
                $scope.idPaciente = $routeParams.idPaciente;
                $scope.fecha = new Date($routeParams.start);
                $scope.duracion = $routeParams.duracion;
                $scope.estado = $routeParams.estado;
                $scope.turnoConfirmado = false;
                
                if($scope.estado == "56a6ca4b2189b35862b6ee8a"){
                    $scope.turnoConfirmado = true;
                }

                $scope.enabled = false;
                //Punto de entrada
                $scope.reservarTurno = false;
                var inst = session.getInstitucionSeleccionada();
                if (!inst || inst._id != $scope.idInstitucion) {
                    $location.path('/');
                }
                else {
                    $scope.institucion = session.getInstitucionSeleccionada();
                    $scope.prestador = session.getPrestadorSeleccionado();
                }
                var prfl = srvcSeguridad.getPerfilUsuario();
                if (prfl.profile.metadata) {
                    if (prfl.profile.metadata._id == $scope.idPaciente) {
                        $scope.paciente = prfl.profile.metadata;
                    }
                    else {
                        srvcPacientes.getPacienteById($scope.idPaciente).then(function (pac) {
                            $scope.paciente = pac;
                        })
                    }
                    if ($scope.paciente) {
                        $scope.pacienteDNI = TodoTurnosAppSession.getTiposDocumento()[$scope.paciente.tipoDocumento].codigo + ' ' + $scope.paciente.numeroDocumento;
                        $scope.pacienteObraSocial = $scope.paciente.obraSocial ? ($scope.paciente.obraSocial.prepaga + ' ' + $scope.paciente.obraSocial.plan) : '';
                        $scope.pacienteNroAfiliado = $scope.paciente.obraSocial ? 'Socio N°' + $scope.paciente.obraSocial.numero : '';
                    }
                }
                else {
                    $location.path('/');
                }

                $scope.openPrint = function(){
                    $scope.enabled = true;
                }


            }]);
} ());