(function() {
    angular.module('TodoTurnosApp').controller('authRedirectController', ['$scope',
        '$rootScope', 'lock', 'authManager', '$location', 'TodoTurnosAppUtils', 
        function($scope, $rootScope, lock, authManager, $location, utils) {
            
            // var accessToken = utils.getUrlParameter('code');
            // console.log('accessToken', accessToken);
            // lock.getUserInfo(accessToken,
            //     function(error, profile) {
            //         profile.user_metadata = undefined;
            //         authManager.authenticate();
            //         $rootScope.$emit('authenticated', { 'profile': profile, 'authResult': authResult });
            //     }, function(err) {
            //     });

        }]);
} ());