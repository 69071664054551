(function () {
    angular.module('TodoTurnosApp')
        .service('srvcPrestadores', ['$http', '$q', 'TodoTurnosAppConfig', 'TodoTurnosAppSession', 'TodoTurnosAppUtils', function ($http, $q, config, session, utils) {
            var _getInstitucion = function (id) {
                var defer = $q.defer();
                $http({ method: 'GET', url: config.urlRestServices + 'instituciones/' + id })
                    .then(function (response) {
                        defer.resolve(response.data);
                    }, function (err) {
                        defer.reject(err);
                    });
                return defer.promise;
            };
            var _getInstitucionLiviana = function (id) {
                var defer = $q.defer();
                $http({ method: 'GET', url: config.urlRestServices + 'instituciones/' + id + '/liviano' })
                    .then(function (response) {
                        defer.resolve(response.data);
                    }, function (err) {
                        defer.reject(err);
                    });
                return defer.promise;
            };
            ///===================================================
            //idInstitucion: identificador de la institucion
            //idPrestador: identifcador del prestador
            //fechaDesde: string formato ISO
            //fechaHasta: string formato ISO
            //summary: devuelve la disponibilidad de un prestador entre las fechas especificadas en la forma de eventos de calendario
            ///===================================================
            var _getDisponibilidadEntreFechas = function (idInstitucion, idPrestador, fechaDesde, fechaHasta, ignorarNoDisp) {
                var defer = $q.defer();
                var pathIgnorarNoDisp = ignorarNoDisp ? '/1' : '';
                $http({ method: 'GET', url: config.urlRestServices + 'instituciones/' + idInstitucion + '/prestadores/' + idPrestador + '/disponibilidad/' + fechaDesde + '/' + fechaHasta + pathIgnorarNoDisp })
                    .then(function (response) {
                        defer.resolve(response.data);
                    }, function (err) {
                        defer.reject(err);
                    });
                return defer.promise;
            };
            ///===================================================
            //idInstitucion: identificador de la institucion
            //idPrestador: identifcador del prestador
            //fechaDesde: string formato ISO
            //fechaHasta: string formato ISO
            //summary: devuelve los turnos de un prestador entre las fechas especificadas
            ///===================================================
            var _getTurnosEntreFechas = function (idInstitucion, idPrestador, fechaDesde, fechaHasta) {
                var defer = $q.defer();
                $http({ method: 'GET', url: config.urlRestServices + 'instituciones/' + idInstitucion + '/prestadores/' + idPrestador + '/turnos/' + fechaDesde + '/' + fechaHasta })
                    .then(function (response) {
                        defer.resolve(response.data);
                    }, function (err) {
                        defer.reject(err);
                    });
                return defer.promise;
            };
            ///===================================================
            //idInstitucion: identificador de la institucion
            //idPrestador: identifcador del prestador
            //summary: devuelve los días no laborables de un prestador en la forma de eventos de calendario
            ///===================================================
            var _getDiasNoLaborables = function (idInstitucion, idPrestador) {
                var defer = $q.defer();
                $http({ method: 'GET', url: config.urlRestServices + 'instituciones/' + idInstitucion + '/prestador/' + idPrestador + '/diasNoLaborables' })
                    .then(function (response) {
                        defer.resolve(response.data);
                    }, function (err) {
                        defer.reject(err);
                    });
                return defer.promise;
            };

            //Esta función obtiene los huecos que quedan libres entres los rangos de disponibilidad para un día
            var obtenerNoDisponibilidadDia = function (disponibilidades, step, fecha) {
                var tComienzoDia = utils.getComienzoDia(fecha).getTime();
                var tComienzoMañana = tComienzoDia + 24 * 3600 * 1000;
                var tDesde = tComienzoDia;
                var step = step * 60000;
                var tHasta = tDesde + step;
                var noDisponibilidades = [];
                var noDisp = null;
                while (tDesde < tComienzoMañana) {
                    if (!checkDisponibilidad(tDesde, tHasta, disponibilidades)) {
                        if (!noDisp) {
                            noDisp = { start: tDesde };
                        }
                    }
                    else {
                        if (noDisp) {
                            noDisp.end = tDesde;
                            noDisponibilidades.push(noDisp);
                            noDisp = null;
                        }
                    }
                    tDesde += step;
                    tHasta += step;
                    if (tDesde == tComienzoMañana && noDisp) {
                        noDisp.end = tDesde;
                        noDisponibilidades.push(noDisp);
                    }
                }
                return noDisponibilidades;
            };

            //Esta función obtiene los rangos de horario libres dado un conjunto de disponibilidades y de turnos
            var _getHorariosLibres = function (disponibilidades, turnos, duracionTurnoMinimo) {
                var estadosTurno = session.getEstadosTurno();
                duracionTurnoMinimo = duracionTurnoMinimo * 60000;
                var horariosLibres = [];
                var ptr, dispoHasta;
                for (var i = 0; i < disponibilidades.length; i++) {
                    disponibilidades[i].fechaHoraInicio = new Date(disponibilidades[i].fechaHoraInicio.replace('Z', '-03:00'));
                    disponibilidades[i].fechaHoraFin = new Date(disponibilidades[i].fechaHoraFin.replace('Z', '-03:00'));
                    ptr = disponibilidades[i].fechaHoraInicio.getTime();
                    dispoHasta = disponibilidades[i].fechaHoraFin.getTime();
                    while (ptr < dispoHasta) {
                        if (!_isHorarioTomado(ptr, turnos, duracionTurnoMinimo, estadosTurno)) {
                            horariosLibres.push({
                                fechaHoraInicio: new Date(ptr),
                                fechaHoraFin: new Date(ptr + duracionTurnoMinimo)
                            });
                        }
                        ptr += duracionTurnoMinimo;
                    }
                }
                return horariosLibres;
            };

            var _isHorarioTomado = function (horario, turnos, duracionTurnoMinimo, estadosTurno) {
                var tStart, tEnd, hStart, hEnd;
                hStart = horario;
                hEnd = horario + duracionTurnoMinimo;
                var now = new Date().getTime() + 4 * duracionTurnoMinimo;
                if (hEnd <= now) {
                    return true;
                }
                for (var i = 0; i < turnos.length; i++) {
                    if (estadosTurno[turnos[i].estado].codigo == 'C'
                        || estadosTurno[turnos[i].estado].codigo == 'R') {
                        tStart = new Date(turnos[i].fechaHoraInicio.replace('Z', '-03:00')).getTime();
                        tEnd = new Date(turnos[i].fechaHoraFin.replace('Z', '-03:00')).getTime();
                        if (hStart >= tStart && hEnd <= tEnd) {
                            return true;
                        }
                    }
                }
                return false;
            };

            //esta función valida si un rango de fechas (desde, hasta) cae dentro de algun rango de disponibilidad del prestador
            var checkDisponibilidad = function (tDesde, tHasta, disponibilidades) {
                for (var i = 0; i < disponibilidades.length; i++) {
                    var val = disponibilidades[i];
                    var dDesde = val.start.getTime();
                    var dHasta = val.end.getTime();
                    if (dDesde >= tHasta)
                        return false;
                    if (tDesde >= dDesde && tHasta <= dHasta)
                        return true;
                }
                return false;
            };
            //API pública
            var o = {
                getInstitucionesNotGeocoded: function (limit) {
                    var defer = $q.defer();
                    $http({ method: 'GET', url: config.urlRestServices + 'instituciones/getNotGeocoded/' + (limit || 0) })
                        .then(function (response) {
                            defer.resolve(response);
                        }, function (err) {
                            defer.reject(err);
                        });
                    return defer.promise;
                },
                getInstitucion: function (id) {
                    return _getInstitucion(id);
                },
                getInstitucionLiviana: function (id) {
                    return _getInstitucionLiviana(id);
                },
                getHorariosLibres: function (idInstitucion, idPrestador, duracionTurnoMinimo, fechaDesde, fechaHasta) {
                    var defer = $q.defer();
                    _getTurnosEntreFechas(idInstitucion, idPrestador, fechaDesde, fechaHasta).then(function (turnos) {
                        _getDisponibilidadEntreFechas(idInstitucion, idPrestador, fechaDesde, fechaHasta).then(function (dispos) {
                            var res = _getHorariosLibres(dispos, turnos, duracionTurnoMinimo);
                            defer.resolve(res);
                        }, function (err) { defer.reject(err) })
                    }, function (err) { defer.reject(err) });
                    return defer.promise;
                },
                ///===================================================
                //idPrestador: identificador del prestador
                ///===================================================
                getPrestador: function (idInstitucion, idPrestador) {
                    var defer = $q.defer();
                    $http({ method: 'GET', url: config.urlRestServices + 'instituciones/' + idInstitucion + '/prestador/' + idPrestador })
                        .then(function (response) {
                            defer.resolve(response.data);
                        }, function (err) {
                            defer.reject(err);
                        });
                    return defer.promise;
                },

                ///===================================================
                // Parametro: alias;
                // Busca a un prestador por Alias
                ///===================================================
                getPrestadorByAlias: function (alias) {
                    var defer = $q.defer();
                    $http({ method: 'GET', url: config.urlRestServices + 'instituciones/getPrestadorByAlias/' + alias })
                        .then(function (response) {
                            defer.resolve(response.data);
                        }, function (err) {
                            defer.reject(err);
                        });
                    return defer.promise;
                },

                ///===================================================
                // Parametro:email;
                // Trae todas las instituciones(datos basicos) donde está el prestador. 
                ///===================================================
                getConsultoriosByEmail: function (email) {
                    var defer = $q.defer();
                    $http({ method: 'GET', url: config.urlRestServices + 'instituciones/getConsultoriosByEmail/' + email })
                        .then(function (response) {
                            defer.resolve(response.data);
                        }, function (err) {
                            defer.reject(err);
                        });
                    return defer.promise;
                },
                ///===================================================
                //idInstitucion: identificador de la institucion
                //idPrestador: identifcador del prestador
                //fechaDesde: string formato ISO
                //fechaHasta: string formato ISO
                //summary: devuelve los turnos de un prestador entre las fechas especificadas
                ///===================================================
                getTurnosEntreFechas: function (idInstitucion, idPrestador, fechaDesde, fechaHasta) {
                    return _getTurnosEntreFechas(idInstitucion, idPrestador, fechaDesde, fechaHasta);
                },
                ///===================================================
                //idInstitucion: identificador de la institucion
                //idPrestador: identificador del prestador
                //fechaDesde: string formato ISO
                //fechaHasta: string formato ISO
                //summary: devuelve la disponibilidad de un prestador entre las fechas especificadas en la forma de eventos de calendario
                ///===================================================
                getDisponibilidadEntreFechas: function (idInstitucion, idPrestador, fechaDesde, fechaHasta, ignorarNoDisp) {
                    return _getDisponibilidadEntreFechas(idInstitucion, idPrestador, fechaDesde, fechaHasta, ignorarNoDisp);
                },

                ///===================================================
                //idInstitucion: identificador de la institucion
                //idPrestador: identificador del prestador
                //summary: devuelve los días no laborables de un prestador en la forma de eventos de calendario
                ///===================================================
                getDiasNoLaborables: function (idInstitucion, idPrestador) {
                    return _getDiasNoLaborables(idInstitucion, idPrestador);
                },
                ///===================================================
                //idInstitucion: identificador de la institucion
                //idPrestador: identifcador del prestador
                //fechaDesde: string formato ISO
                //fechaHasta: string formato ISO
                //summary: devuelve los turnos confirmados o solicitados (abiertos) de un prestador entre las fechas especificadas
                ///===================================================
                getTurnosAbiertosEntreFechas: function (idInstitucion, idPrestador, fechaDesde, fechaHasta) {
                    var defer = $q.defer();
                    $http({ method: 'GET', url: config.urlRestServices + 'instituciones/' + idInstitucion + '/prestadores/' + idPrestador + '/turnosAbiertos/' + fechaDesde + '/' + fechaHasta })
                        .then(function (response) {
                            defer.resolve(response.data);
                        }, function (err) {
                            defer.reject(err);
                        });
                    return defer.promise;
                },

                ///===================================================
                //idInstitucion
                //idPrestador
                //duracionTurnoMinimo: duracion de turno minimo del prestador
                //turno: turno a validar contra el rango de disponibilidades
                //summary: devuelve true si el turno está enmarcado dentro de los rangos de disponibilidad especificado por parámetro y false si no
                ///===================================================
                checkDisponibilidadPrestadorById: function (idInstitucion, idPrestador, duracionTurnoMinimo, turno) {
                    var defer = $q.defer();
                    var tStart = Date.parse(turno.start);
                    var tEnd = Date.parse(turno.end);
                    _getDisponibilidadEntreFechas(idInstitucion, idPrestador, turno.start.replace('-03:00', 'Z'), new Date(tStart + 24 * 3600 * 1000).toISOString()).then(function (resDisp) {
                        var disps = [];
                        for (var i = 0; i < resDisp.length; i++) {
                            var d = {
                                start: new Date(resDisp[i].fechaHoraInicio.replace('Z', '-03:00')),
                                end: new Date(resDisp[i].fechaHoraFin.replace('Z', '-03:00'))
                            };
                            disps.push(d);
                        }
                        var noDisps = obtenerNoDisponibilidadDia(disps, duracionTurnoMinimo, new Date(tStart));
                        for (var i = 0; i < noDisps.length; i++) {
                            var nodisp = noDisps[i];
                            var ndStart = nodisp.start;
                            var ndEnd = nodisp.end;
                            if ((tStart > ndStart && tStart < ndEnd) || (tEnd > ndStart && tEnd < ndEnd)) {
                                defer.resolve(false);
                            }
                        }
                        defer.resolve(true);
                    }, function (err) {
                        defer.reject(err);
                    });
                    return defer.promise;
                },
                ///===================================================
                //turno: objeto a actualizar
                //duracionMinima: duracion de turno minima para el prestador (en minutos). Se usa para validar la duración máxima del turno
                //summary: Actualiza un turno en la bd junto con el paciente asociado. Si el turno no existe, lo inserta.
                ///===================================================
                upsertTurno: function (turno, duracionMinima) {
                    var durMax = duracionMinima * config.duracionMaximaTurno * 60000;
                    var durFix = false;
                    if ((turno.fechaHoraFin - turno.fechaHoraInicio) > durMax) {
                        turno.fechaHoraFin = new Date(turno.fechaHoraInicio + durMax);
                        durFix = true;
                    }
                    var defer = $q.defer();
                    var fnOk = function (response) {
                        defer.resolve({ data: response.data, refresh: durFix });
                    };
                    $http({ method: 'PUT', url: config.urlRestServices + 'turnos/', data: turno })
                        .then(fnOk, function (err) {
                            defer.reject(err);
                        });
                    return defer.promise;
                },
                getConfirmacionTurno: function (id) {
                    var defer = $q.defer();
                    $http({ method: 'GET', url: config.urlRestServices + 'turnos/' + id + '/confirmacion' })
                        .then(function (response) {
                            defer.resolve(response.data);
                        }, function (err) {
                            defer.reject(err);
                        });
                    return defer.promise;
                },
                saveConfirmacionTurno: function (turno) {
                    var defer = $q.defer();
                    $http({ method: 'PUT', url: config.urlRestServices + 'turnos/confirmacion', data: turno })
                        .then(function (response) {
                            defer.resolve(response.data);
                        }, function (err) {
                            defer.reject(err);
                        });
                    return defer.promise;
                },
                ///===================================================
                //paciente: Paciente a dar de alta en la institucion. Se pasa completo por si hay que hacer merge con uno ya existente.
                //idInstitucion: Id de la institucion a agregar en el array del paciente
                //summary: Agrega una institucion al paciente para que pueda ser gestionada dentro de la app frontend.
                ///===================================================
                agregarInstitucionAPaciente: function (paciente, idInstitucion) {
                    var defer = $q.defer();

                    $http({ method: 'PUT', url: config.urlRestServices + 'instituciones/' + idInstitucion + '/pacienteTurno', data: paciente })
                        .then(function (response) {
                            defer.resolve(response.data);
                        }, function (err) {
                            defer.reject(err);
                        });
                    return defer.promise;
                },
                ///===================================================
                //summary: Crea una cuenta en Auth0 (Profesionales) y retorna un token para inicio de sesion
                ///===================================================
                registerAuth0User: function (prestador) {
                    var defer = $q.defer();
                    var fnOk = function (response) {
                        defer.resolve(response);
                    };
                    $http({ method: 'PUT', url: config.urlRestServices + 'instituciones/registerAuth0User ', data: prestador })
                        .then(fnOk, function (err) {
                            defer.reject(err);
                        });
                    return defer.promise;
                },
                ///===================================================

                //idInstitucion: institucion del turno
                //idPrestador: prestador del turno
                //inicio: fecha de inicio del turno
                //fin: fecha de fin del turno
                //summary: devuelve si existe algún turno solapado para la institucion y prestador
                ///===================================================
                getTurnosSolapados: function (idInstitucion, idPrestador, inicio, fin) {
                    var defer = $q.defer();
                    $http({ method: 'GET', url: config.urlRestServices + 'turnos/solapamiento/institucion/' + idInstitucion + '/prestador/' + idPrestador + '/inicio/' + inicio + '/fin/' + fin })

                        .then(function (response) {
                            defer.resolve(response.data);
                        }, function (err) {
                            defer.reject(err);
                        });
                    return defer.promise;
                }
            };
            return o;
        }]);
}());