(function () {
    angular.module('TodoTurnosApp')
        .directive('detallePrestador', function () {
            return {
                restrict: 'E',
                templateUrl: '../views/directives/detallePrestador.html',
                scope: {
                    prestador: '=',
                    institucion: '='
                }
            };
        })
} ());