(function () {
    angular.module('TodoTurnosApp')
        .controller('reservarTurnoPaso2Controller', ['$routeParams', '$scope', 'srvcPrestadores', '$location', 'TodoTurnosAppSession', 'srvcSeguridad',

            function ($routeParams, $scope, srvcPrestadores, $location, session, srvcSeguridad) {
                $scope.idInstitucion = $routeParams.idInstitucion;
                $scope.idPrestador = $routeParams.idPrestador;
                $scope.fechaTurno = $routeParams.start;
                console.log('$routeParams.start', $routeParams.start);
                $scope.duracion = $routeParams.duracion;
                $scope.reservarTurno = function () {
                    $location.path($location.path() + '/reservarTurno/');
                };
                //Punto de entrada
                $scope.reservarTurno = true;
                var prfl = srvcSeguridad.getPerfilUsuario();
                if (prfl.profile.metadata) {
                    $scope.paciente = prfl.profile.metadata;
                }
                else {
                    $location.path('/');
                }
                var inst = session.getInstitucionSeleccionada();
                if (!inst || inst._id != $scope.idInstitucion) {
                    $location.path('/');
                }
                else {
                    $scope.institucion = session.getInstitucionSeleccionada();
                    $scope.prestador = session.getPrestadorSeleccionado();
                }
            }]);

} ());