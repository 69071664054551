(function () {
    angular.module('TodoTurnosApp')
        .directive('pacienteHijo', function () {
            return {
                restrict: 'E',
                templateUrl: '../views/directives/pacienteHijo.html',
                scope: {
                    paciente: '=',
                    otroPaciente: '='
                },
                controller: function ($scope, TodoTurnosAppSession, srvcPacientes, srvcSeguridad, TodoTurnosAppConfig, TodoTurnosAppUtils) {
                    $scope.close = function () {
                        $scope.otroPaciente = false;
                    };
                    $scope.validarDocumento = function () {
                        var dni = $scope.pacienteNuevo.numeroDocumento;
                        var regex = TodoTurnosAppConfig.regExpressions.dni;
                        $scope.documentoValido = (dni && regex.test(dni));
                    };
                    $scope.validarNombre = function () {
                        var nombre = $scope.pacienteNuevo.nombre;
                        $scope.nombreValido = (nombre && nombre.length > 5);
                    };
                    $scope.isValid = function () {
                        return $scope.nombreValido && $scope.documentoValido && $scope.pacienteNuevo.tipoDocumento && $scope.pacienteNuevo.sexo;
                    };
                    var getFamilia = function () {
                        srvcPacientes.getFamiliaByEmail($scope.pacientePadre.email)
                            .then(function (pacientes) {
                                var sorted = [];
                                for (var i = 0; i < pacientes.length; i++) {
                                    var item = {};
                                    item.numero = pacientes[i].email.indexOf('+') > -1 ? pacientes[i].email.substring(pacientes[i].email.lastIndexOf("+") + 1, pacientes[i].email.lastIndexOf("@")) : 0;
                                    item.numero = parseInt(item.numero);
                                    item.paciente = pacientes[i];
                                    if (!isNaN(item.numero))
                                        sorted.push(item);
                                }

                                sortResults('numero', true);
                                //ordeno lista de pacientesFrecuentes por su +XX del mail
                                function sortResults(prop, asc) {
                                    sorted = sorted.sort(function (a, b) {
                                        if (asc) {
                                            return (a[prop] > b[prop]) ? 1 : ((a[prop] < b[prop]) ? -1 : 0);
                                        } else {
                                            return (b[prop] > a[prop]) ? 1 : ((b[prop] < a[prop]) ? -1 : 0);
                                        }
                                    })
                                }
                                $scope.pacientesFrecuentes = [];
                                for (var i = 0; i < sorted.length; i++) {
                                    $scope.pacientesFrecuentes.push(sorted[i].paciente);
                                }
                            });
                    };
                    $scope.nuevoPaciente = function () {
                        $scope.pacienteNuevo.estado = 'P';
                        var lastEmail = ''; var lastIdx = 0;
                        if ($scope.pacientesFrecuentes.length > 0)
                            lastEmail = $scope.pacientesFrecuentes[$scope.pacientesFrecuentes.length - 1].email;
                        if (lastEmail.length > 0) {
                            lastIdx = lastEmail.indexOf('+') > -1 ? lastEmail.substring(lastEmail.lastIndexOf("+") + 1, lastEmail.lastIndexOf("@")) : 0;
                        }
                        $scope.pacienteNuevo.email = lastEmail.replace(lastIdx, parseInt(lastIdx) + 1);
                        srvcPacientes.insert($scope.pacienteNuevo).then(function (res) {
                            $scope.otroPaciente = false;
                            $scope.paciente = res;
                        }, function (err) {
                            if (err.data.exists)
                                toastr.error("Ya existe un paciente con el mismo Documento.", config.toastrErrorConfig);
                            else
                                toastr.error("Ha ocurrido un error al dar de alta el paciente. Por favor intentelo nuevamente en unos instantes. ", config.toastrErrorConfig);
                        });
                    }
                    //Punto de entrada
                    $scope.tiposDocumento = TodoTurnosAppUtils.keysToArray(TodoTurnosAppSession.getTiposDocumento());
                    $scope.sexos = TodoTurnosAppUtils.keysToArray(TodoTurnosAppSession.getSexos());
                    $scope.pacienteNuevo = {};
                    var prfl = srvcSeguridad.getPerfilUsuario();
                    if (prfl.profile.metadata) {
                        $scope.pacientePadre = prfl.profile.metadata;
                    }
                    getFamilia();
                    $scope.validarDocumento();
                    $scope.validarNombre();
                }
            };
        })
} ());