(function() {
    'use strict';
    angular.module('TodoTurnosApp')
        .controller('showInfoController',
        ['$scope', 
        '$location',
        '$uibModalInstance',
        'prestador',
        'tel',
         function ($scope, $location,$uibModalInstance,prestador,tel) {
            $scope.prestador = prestador;
            $scope.tels = tel;
            
            $scope.articulo ="";
            if ($scope.prestador.perfil.nombre.indexOf("Dra")> -1){
                $scope.articulo ="la";
            } 
            else{
                $scope.articulo ="el";
            }
            

            
            
            
            $scope.ok = function () {
                $uibModalInstance.close();
            };

    }]);
})();