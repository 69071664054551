(function () {
    //Módulo principal de la aplicación
    angular.module('TodoTurnosApp', ['ngRoute', 'ui.calendar', 'ui.bootstrap', 'auth0.lock', 'angular-jwt', 'toastr', 'ngPrint', 'infinite-scroll', 'datePicker', 'angular-loading-bar', 'ngSanitize','oitozero.ngSweetAlert','ngFileUpload'])
        .controller('mainController',
        ['$scope', '$routeParams', '$location', 'srvcSeguridad', '$rootScope', 'TodoTurnosAppSession', 'srvcBusqueda', '$uibModal', '$window', function ($scope, $routeParams, $location, srvcSeguridad, $rootScope, session, srvcBusqueda, $uibModal, $window) {
            $scope.abrirMisTurnos = function () {
                $location.path("misTurnos");
            }

            $scope.openBlog = function(){
                window.open("http://academy.todoturnos.com");
                return false;
            };
        
        $scope.openMenuRubros = function(){
            var state = $(".menu-rubros").css("display");
                if(state =="none"){
                    $(".menu-rubros").css("display","inline");
                    var pos = $("#rubros-item").offset();
                    $(".menu-rubros").css("left",pos.left);
                }else{
                    $(".menu-rubros").css("display","none");
                }
        };
            $scope.openMedicoSeccion = function (link) {
                $location.path("/");
                $('#' + link).animatescroll();
            }

            $scope.openFuncionalidades = function(){
                $location.path("funcionalidades");
                $scope.headCentral = 'medico';
                
            }
            $scope.openPlanes = function(){
                $location.path("planes");
                $scope.headCentral = 'medico';
                
            }

            $scope.$on('$viewContentLoaded', function(event) {
                //console.log("cargo vista" ,$location.url());
                //console.log("VENTANITA" ,$window);
                $window.ga('send', 'pageview', { page: $location.url() });
              });

            // var _redirect = function () {
            //     var ruta = session.getUltimaRuta();
            //     if (ruta && ruta != 'null') {
            //         $location.path(ruta);
            //         session.setUltimaRuta(null);
            //     }
            // };
            $rootScope.$on('profileLoaded', function () {
                determinarMenu();
                // _redirect();

            });
            $scope.openSection = function(op){
                console.log("cambio de opción:", op);
            }
            $scope.goTo = function (pagina) {
                switch (pagina) {
                    case 'Medico':
                        {
                            $location.path("medicos");
                            $scope.headCentral = 'medico';
                            break;
                        }
                    case 'Buscador':
                    $location.path("buscador");
                    break;
                    case 'LoginPaciente':
                        $location.path("login");
                        break;
                    case 'LoginPrestador':
                        $location.path("login");
                        break;
                    case 'RegistroPaciente':
                        $location.path("login");
                        break;
                    case 'RegistroPrestador':
                        $location.path("login");
                        break;
                }

            }
            $scope.openPreLogin = function (clientId) {
                
                var modalInstance = $uibModal.open({
                    animation: $scope.animationsEnabled,
                    ariaLabelledBy: 'modal-title',
                    ariaDescribedBy: 'modal-body',
                    templateUrl: './views/modals/preLogin.html',
                    controller: 'preLoginController',
                    size: 'md',
                    resolve: {
                        appClientId: function () {
                            return clientId;
                        }
                    }
                });
            }

            $rootScope.$on('emailVerified', function (event, clientId) {
                
                $scope.openPreLogin(clientId);
            });

            $rootScope.$on('$routeChangeStart', function (event, nextRoute, currentRoute) {
                estoyLogueado = srvcSeguridad.getPerfilUsuario()

                if (estoyLogueado) {
                    $scope.headDerecho = 'logueado';
                    $('.menuitem-ingrese').css('margin-top','18px');
                }
                else {
                    $scope.headDerecho = 'noLogueado';
                }

                switch (nextRoute.$$route.originalPath) {
                    case '/buscador':
                        {
                            if (estoyLogueado) {
                                $scope.headCentral = 'nada';
                            }
                            else {
                                $scope.headCentral = 'noLogueado';
                            }

                            break;
                        }
                    case '/':
                    case '/funcionalidades':
                    case '/planes':
                        {
                            $scope.headCentral = 'medico';
                            break;
                        }
                    default:
                        {
                            $scope.headCentral = 'buscador';
                            break;
                        }
                }


            });

            var determinarMenu = function () {
                $scope.perfilUsuarioLogueado = srvcSeguridad.getPerfilUsuario();
                
                if ($scope.perfilUsuarioLogueado) {
                    $scope.headCentral = 'nada';
                    $scope.headDerecho = 'logueado';
                }
                else {
                    $scope.headCentral = 'noLogueado';
                    $scope.headDerecho = 'noLogueado';
                }
            }

            $scope.buscar = function (selected) {
                if (!$scope.fieldPlace) {
                    $scope.fieldPlace = null;
                }
                $location.path('/resultados/q/' + selected + "/lugar/" + $scope.fieldPlace);

            }

            $scope.getCacheItems = function (val) {
                if (val) {
                    srvcBusqueda.getCacheItemsContainsValue(val).then(function (res) {
                        $scope.array = res;
                    });

                }

            }

            $scope.selectItem = function ($item, $model, $label, $event) {
                $location.path('/medicos/' + $item.idInstitucion + "/" + $item.idPrestador);
            }

            //Punto de entrada
            determinarMenu();

        
            $scope.precio = {
                business: 'Precio a medida/mes',
                premium: '6500/mes',
                standard: '2900/mes'
            }
            
            $scope.mostrarPrecios = function(opcion){
               
                switch(opcion){
    
                    case 'mensual':
                            $scope.precio =  {business: 'Precio a medida/mes', premium: '6500/mes', standard: '2900/mes'}
                    break;
    
                    case 'anual':
                            $scope.precio =  {business: 'Precio a medida/año', premium: '65000/año', standard: '29000/año'}
                    break;
                    default: break;     
                }

            }
            // $scope.headCentral = 'medico';
            // $scope.headDerecho = 'noLogueado';


        }]);

} ()); 
