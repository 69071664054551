(function () {
    angular.module('TodoTurnosApp').controller('LoginCtrl', [
        '$scope',
        'authService',
        'srvcSeguridad',
        '$rootScope',
        '$location',
        'toastr',
        'TodoTurnosAppConfig',
        'TodoTurnosAppSession',
        function ($scope, authService, srvcSeguridad, $rootScope, $location, toastr, config, session) {
            $scope.login = function () {
                var authParams = {
                    authParams: { scope: 'openid' }
                };
                var options = {
                    avatar: null,
                    language: 'es'
                };
                authService.login(
                    // {

                //     onestep: true,
                //     signInButtonText: "Ingresar",
                //     emailPlaceholder: "Correo",
                //     passwordPlaceholder: "Contraseña",
                //     separatorText: "o",
                //     showIcon: true,
                //     showSignup: true,
                //     signupText: "Registrarme",
                //     showForgot: true,
                //     forgotText: "Olvidé mi contraseña",
                //     closable: false,
                //     autofocus: true,
                //     icon: 'http://todoturnos.com/logo.png',
                //     primaryColor: '#1ABB9C',
                //     rememberLastLogin: true,
                //     connections: ['Username-Password-Authentication']
                //     , dict: 'es'
                // }
                );
            };
            $scope.login();
        }]);
} ());