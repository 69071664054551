(function () {
    angular.module('TodoTurnosApp')
        .controller('PortadaPrestadorController1', ['$routeParams', '$scope', 'srvcPrestadores', '$location',
            'TodoTurnosAppSession',

            function ($routeParams, $scope, srvcPrestadores, $location, session) {
                var idInstitucion = $routeParams.idInstitucion;
                var idPrestador = $routeParams.idPrestador;

                var getPrestador = function (institucion, idPrestador) {
                    for (var i = 0; i < institucion.prestadores.length; i++) {
                        if (institucion.prestadores[i].prestador_id == idPrestador)
                            return institucion.prestadores[i];
                    }
                    return null;
                };

                $scope.reservarTurno = function () {
                    $location.path($location.path() + '/reservarTurno/');
                };

                var setScope = function () {
                    $scope.institucion = session.getInstitucionSeleccionada();
                    $scope.prestador = session.getPrestadorSeleccionado();
                };

                //Punto de entrada
                var inst = session.getInstitucionSeleccionada();
                if (inst && inst._id == idInstitucion) {
                    session.setPrestadorSeleccionado(getPrestador(inst, idPrestador));
                    setScope();
                }
                else {
                    srvcPrestadores.getInstitucion(idInstitucion)
                        .then(function (institucion) {
                            session.setInstitucionSeleccionada(institucion);
                            session.setPrestadorSeleccionado(getPrestador(institucion, idPrestador));
                            setScope();
                        });
                }
            }]);
} ());