(function () {
    angular.module('TodoTurnosApp')
        .controller('registroProfesionalController',
            ['$scope'
                , 'srvcPrestadores'
                , 'srvcSeguridad'
                , 'TodoTurnosAppConfig'
                , 'toastr'
                , 'TodoTurnosAppSession'
                , 'TodoTurnosAppUtils'
                , '$location'
                , '$timeout'
                , function ($scope, srvcPrestadores, srvcSeguridad, config, toastr, session, utils, $location, $timeout) {

                    //Obtiene el dominio donde estoy parado, el segundo parametro es booleando y te permite sacarle el subdominio al dominio
                    function getDomain(url, subdomain) {
                        subdomain = subdomain || false;

                        url = url.replace(/(https?:\/\/)?(www.)?/i, '');

                        if (!subdomain) {
                            url = url.split('.');

                            url = url.slice(url.length - 2).join('.');
                        }

                        if (url.indexOf('/') !== -1) {
                            return url.split('/')[0];
                        }

                        return url;
                    }

                    $scope.validarNombre = function () {
                        var regex = config.regExpressions.nombre;
                        $scope.nombreValido = ($scope.prestador.nombre && $scope.prestador.nombre.length > 5 && regex.test($scope.prestador.nombre));
                        $scope.nombreVacio = $scope.prestador.nombre.length = 0;
                    };

                    $scope.validarTelefono = function () {
                        var tel = $scope.prestador.telefono;
                        var regex = config.regExpressions.telefono;
                        $scope.telefonoValido = (tel && !isNaN(tel) && regex.test(tel));
                    };

                    $scope.validarPassword = function () {
                        var password = $scope.prestador.password;
                        $scope.passwordValida = (password && password.length > 0);
                    };

                    $scope.validarEmail = function () {
                        var email = $scope.prestador.email;
                        var regex = config.regExpressions.email;
                        $scope.emailExistente = false;
                        $scope.emailValido = (email && regex.test(email));
                    };

                    $scope.isvalid = function () {
                        return ($scope.emailValido && $scope.passwordValida && $scope.nombreValido && $scope.telefonoValido) && !$scope.creando;
                    };

                    $scope.crear = function () {
                        $scope.validarNombre();
                        $scope.validarEmail();
                        $scope.validarPassword();
                        $scope.validarTelefono();
                        if ($scope.isvalid()) {
                            $scope.creando = true;
                            //logica de creacion de usuario en Auth0
                            var prestador = { "perfil": { "email": $scope.prestador.email, "nombre": $scope.prestador.nombre, "telefono": $scope.prestador.telefono }, "password": $scope.prestador.password };
                            srvcPrestadores.registerAuth0User(prestador).then(function (loginInfo) {
                                $scope.creando = false;
                                var token = loginInfo.data.token;
                                var profile = loginInfo.data.userInfo;
                                profile.user_metadata = undefined;
                                var cookie = { token: token, profile: profile, prestador: $scope.prestador };
                                document.cookie = "registro=" + JSON.stringify(cookie) + ";domain=" + getDomain(window.location.hostname, false) + ";";
                                window.location.replace(config.urlFrontEnd+'#!/register');
                            },
                                function (err) {
                                    $scope.creando = false;
                                    if (err.data.usuarioExistente) {
                                        //El usuario ya existe en auth0
                                        $scope.emailValido = false;
                                        $scope.emailExistente = true;
                                    }
                                });
                        }
                    }

                    //Punto de entrada
                    $scope.prestador = { email: "", nombre: "", telefono: "", password: "" }
                    $scope.emailValido = true;
                    $scope.nombreValido = true;
                    $scope.telefonoValido = true;
                    $scope.passwordValida = true;
                    $scope.creando = false;

                }]);

}());