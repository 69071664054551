(function () {
    angular.module('TodoTurnosApp')
        .controller('reservarTurnoController', [
            '$routeParams',
            '$scope',
            'srvcPrestadores',
            '$location',
            'TodoTurnosAppSession',
            'srvcSeguridad',

            function ($routeParams, $scope, srvcPrestadores, $location, session,srvcSeguridad) {
                
                $scope.idInstitucion = $routeParams.idInstitucion;
                $scope.idPrestador = $routeParams.idPrestador;
                $scope.fechaTurno = new Date($routeParams.start);

                $scope.duracion = $routeParams.duracion;
 
                $scope.reservarTurno = function () {
                    $location.path($location.path() + '/reservarTurno/');
                };
                //Punto de entrada
                $scope.reservarTurno = true;
                var prfl = srvcSeguridad.getPerfilUsuario();
                if (prfl.profile.metadata) {
                    $scope.paciente = prfl.profile.metadata;
                }
                else {
                    $location.path('/');                    
                }

                var getPrestador = function (institucion, idPrestador) {
                    for (var i = 0; i < institucion.prestadores.length; i++) {
                        if (institucion.prestadores[i].prestador_id == idPrestador)
                            return institucion.prestadores[i];
                    }
                    return null;
                };
               

                var inst = session.getInstitucionSeleccionada();
                // if (inst) {
                //     if (inst._id == $scope.idInstitucion){
                //         session.setPrestadorSeleccionado(getPrestador(inst, idPrestador));
                //         setScope();
                //     }
                    
                // }
                // else {
                    srvcPrestadores.getInstitucion($scope.idInstitucion)
                        .then(function (institucion) {
                            $scope.institucion = institucion;
                            $scope.prestador = getPrestador(institucion, $scope.idPrestador)
                            session.setInstitucionSeleccionada(institucion);
                            session.setPrestadorSeleccionado($scope.prestador);
                            
                        });
                // }
                //var inst = session.getInstitucionSeleccionada();
                // if (!inst || inst._id != $scope.idInstitucion) {
                //     $location.path('/');
                // }
                // else {
                //     $scope.institucion = session.getInstitucionSeleccionada();
                //     $scope.prestador = session.getPrestadorSeleccionado();
                // }
            }]);

} ());