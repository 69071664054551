(function () {
    'use strict';

    angular.module('TodoTurnosApp')
        .controller('turnosPortablesController', turnosPortablesController);

    turnosPortablesController.$inject = [
        '$scope',
        '$routeParams',
        'srvcPrestadores',
        '$location',
        'srvcPacientes',
        'TodoTurnosAppSession',
        'SweetAlert',
        'TodoTurnosAppUtils'

    ];

    function turnosPortablesController($scope, $routeParams, srvcPrestadores, $location, srvcPacientes, TodoTurnosAppSession, SweetAlert, utils) {
        $scope.idPrestador = $routeParams.idPrestador;
        $scope.idInstitucion = $routeParams.idInstitucion;
        $scope.start = $routeParams.start;


        $('header').css('display', 'none');
        $('body').css('padding-top', '0px');
        $('footer').css('display', 'none');
    };
})();
