(function () {
    angular.module('TodoTurnosApp')
        .controller('buscadorController',
        ['$scope'
            , '$routeParams'
            , '$location'
            , 'srvcBusqueda'
            , 'srvcPacientes'
            , '$uibModal'
            , 'TodoTurnosAppSession'
            , '$rootScope'
            , function ($scope, $routeParams, $location, srvcBusqueda, srvcPacientes, $uibModal, session, $rootScope) {

                var _redirect = function () {
                    var ruta = session.getUltimaRuta();
                    if (ruta != 'null') {
                        $location.path(ruta);
                    }
                };
                $rootScope.$on('profileLoaded', function () {
                    // _redirect();
                });

                /*Declaraciones y invocaciones*/


                $scope.array = [];
                $scope.prepagas = ["ACCORD", "GALENO", "MEDICUS", "OMINT", "OSDE", "SWISS"];
                $scope.prepaga = "ELEGIR";

                $scope.setOS = function (itemSelected) {
                    $scope.prepaga = itemSelected;
                }



                /*Funciones*/
                $scope.selectItem = function ($item, $model, $label, $event) {
                    var nombre = $item.nombre.replace(new RegExp(" ", 'g'), "-");

                    $location.path('/medicos/' + nombre + "-" + $item.alias);
                }


                $scope.buscar = function () {
                    if (!$scope.selected) {
                        $scope.selected = null;
                    }
                    if (!$scope.fieldPlace) {
                        $scope.fieldPlace = null;
                    }
                    if ($scope.prepaga != "ELEGIR") {
                        $scope.selected += " " + $scope.prepaga;
                    }
                    $location.path('/resultados/q/' + $scope.selected + "/lugar/" + $scope.fieldPlace);

                }


                $scope.getCacheItems = function (val) {
                    $scope.array = [];
                    if (val.length > 2) {
                        srvcBusqueda.getCacheItemsContainsValue(val, 10).then(function (res) {
                            $scope.array = res;
                        });

                    }

                }



                if ($routeParams.clientId) {
                    $rootScope.$broadcast('emailVerified', { 'clientId': $routeParams.clientId });
                }


            }]);


} ());