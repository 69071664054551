(function() {
    angular.module('TodoTurnosApp')
        .directive('detalleTurno', function() {
            return {
                restrict: 'E',
                templateUrl: '../views/directives/detalleTurno.html',
                scope: {
                    paciente: '=',
                    institucion: '=',
                    prestador: '=',
                    fecha: '=',
                    duracion: '=',
                    mostrarBotonReserva: '=',
                    mostrarBotonOtroPaciente: '=',
                },
                controller: function($scope, $location, TodoTurnosAppSession, TodoTurnosAppUtils, srvcPrestadores, toastr, TodoTurnosAppConfig) {
                    $scope.paraOtro = function() {
                        $scope.otroPaciente = true;
                    };
                    var getEstadoTurno = function(codigo) {
                        var arr = TodoTurnosAppUtils.keysToArray(TodoTurnosAppSession.getEstadosTurno());
                        for (var i = 0; i < arr.length; i++) {
                            if (arr[i].codigo == codigo)
                                return arr[i]._id;
                        }
                    };

                    var initTurno = function(paciente, prestador, institucion) {
                        var turno = {};
                        turno._id = '0';
                        turno.estado = getEstadoTurno('R');
                        turno.confirmoPaciente = true;
                        turno.notificarPaciente = true;
                        // turno.fechaHoraInicio = new Date($scope.fecha.replace('Z', '-03:00')).toLocalISOString();
                        turno.fechaHoraInicio = new Date($scope.fecha).toLocalISOString();
                        // turno.fechaHoraFin = new Date(Date.parse(new Date($scope.fecha.replace('Z', '-03:00'))) + $scope.duracion * 60000).toLocalISOString();
                        turno.fechaHoraFin = new Date(Date.parse(new Date($scope.fecha)) + $scope.duracion * 60000).toLocalISOString();
                        esSobreturno = false;
                        if (paciente) {
                            turno.idPaciente = paciente._id;
                            turno.descripcion = paciente.nombre;
                            TodoTurnosAppUtils.audit(turno);
                        }
                        if (institucion) {
                            turno.idInstitucion = institucion._id;
                        }
                        if (prestador) {
                            turno.idPrestador = prestador.prestador_id;
                        }
                        return turno;
                    }

                    $scope.persist = function() {
                        var turno = initTurno($scope.paciente, $scope.prestador, $scope.institucion);
                        srvcPrestadores.upsertTurno(turno, $scope.duracion).then(function(res) {
                            var estadoTurno = res.data.estado;
                            toastr.success("El turno ha sido reservado.", TodoTurnosAppConfig.toastrMensajeConfig);

                            srvcPrestadores.agregarInstitucionAPaciente($scope.paciente, $scope.institucion._id).then(function(res) {
                                $location.path('/institucion_1/' + $scope.institucion._id + '/prestador/' + $scope.prestador.prestador_id + '/paciente/' + $scope.paciente._id + '/turno/' + $scope.fecha + '/' + $scope.duracion + '/estado/' + estadoTurno);
                            }, function(err) {
                                $location.path('/institucion_1/' + $scope.institucion._id + '/prestador/' + $scope.prestador.prestador_id + '/paciente/' + $scope.paciente._id + '/turno/' + $scope.fecha + '/' + $scope.duracio + '/estado/' + estadoTurno);
                            });



                        }, function(err) {
                            toastr.error("Ha ocurrido un error al guardar el turno. Por favor intente nuevamente más tarde.", TodoTurnosAppConfig.toastrErrorConfig);
                        });
                    };
                    $scope.otroPaciente = false;
                    $scope.$watch(function($scope) { return $scope.otroPaciente },
                        function() {
                            $scope.mostrarBotonOtroPacienteFinal = $scope.mostrarBotonOtroPaciente && !$scope.otroPaciente;
                        }
                    );

                    $scope.$watch(function($scope) { return $scope.paciente },
                        function() {
                            if ($scope.paciente) {
                                $scope.pacienteDNI = TodoTurnosAppSession.getTiposDocumento()[$scope.paciente.tipoDocumento].codigo + ' ' + $scope.paciente.numeroDocumento;
                                $scope.pacienteObraSocial = $scope.paciente.obraSocial ? ($scope.paciente.obraSocial.prepaga + ' ' + $scope.paciente.obraSocial.plan) : '';
                                $scope.pacienteNroAfiliado = ($scope.paciente.obraSocial && $scope.paciente.obraSocial.numero) ? 'Socio N°' + $scope.paciente.obraSocial.numero : '';
                            }
                        }
                    );

                    // if ($scope.paciente) {
                    //     $scope.pacienteDNI = TodoTurnosAppSession.getTiposDocumento()[$scope.paciente.tipoDocumento].codigo + ' ' + $scope.paciente.numeroDocumento;
                    //     $scope.pacienteObraSocial = $scope.paciente.obraSocial.prepaga + ' ' + $scope.paciente.obraSocial.plan;
                    //     $scope.pacienteNroAfiliado = $scope.paciente.obraSocial ? 'Socio N°' + $scope.paciente.obraSocial.prepaga : '';
                    // }
                    if ($scope.prestador) {
                        if ($scope.prestador.perfil.profesiones && $scope.prestador.perfil.profesiones.length > 0) {
                            if ($scope.prestador.perfil.profesiones[0].especialidades && $scope.prestador.perfil.profesiones[0].especialidades.length > 0)
                                $scope.prestadorNombre = $scope.prestador.perfil.nombre + ' / ' + $scope.prestador.perfil.profesiones[0].especialidades[0].nombre;
                        }
                    }
                    if ($scope.institucion) {
                        $scope.institucionDireccion = $scope.institucion.direccion.domicilio + ' ' + $scope.institucion.direccion.altura;
                        if ($scope.institucion.telefonos) {
                            $scope.institucionTelefonos = '';
                            for (var i = 0; i < $scope.institucion.telefonos.length; i++) {
                                $scope.institucionTelefonos += $scope.institucion.telefonos[0] + ' / ';
                            }
                            $scope.institucionTelefonos = $scope.institucionTelefonos.substr(0, $scope.institucionTelefonos.length - 3);
                        }
                    }
                }
            }
        })
} ());