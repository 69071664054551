(function () {
    angular.module('TodoTurnosApp').config(['$routeProvider', '$httpProvider',
        function ($routeProvider, $httpProvider, $locationProvider) {
            // $locationProvider.hashPrefix('');
            $httpProvider.defaults.headers.common['Content-Type'] = 'text/html; charset=utf-8';
            $httpProvider.defaults.headers.common['Accept'] = 'text/html; charset=utf-8';
            $routeProvider
                .when('/login', {
                    templateUrl: './views/login.tpl.html',
                    controller: 'LoginCtrl',
                    label: ""
                })
                .when('/institucion/:idInstitucion', {
                    templateUrl: './views/homeInstitucion.html',
                    controller: 'homeInstitucionController',
                    label: ""
                    // requiresLogin: true
                })
                .when('/institucion_1/:idInstitucion/prestador/:idPrestador', {
                    templateUrl: './views/homePrestador.1.html',
                    controller: 'PortadaPrestadorController1',
                    label: ""
                    // requiresLogin: true
                })
                .when('/consultorio/:idInstitucion', {
                    templateUrl: './views/portadaInstitucion.html',
                    controller: 'portadaInstitucionController',
                    label: ""
                    // requiresLogin: true
                })
                .when('/institucion_1/:idInstitucion/prestador/:idPrestador/reservarTurno', {
                    templateUrl: './views/reservarTurno.html',
                    controller: 'reservarTurnoController',
                    label: ""
                    // requiresLogin: true
                })
                .when('/medicos/:idInstitucion/:idPrestador/reservarTurno/:start/:duracion', {
                    templateUrl: './views/reservarTurno.html',
                    controller: 'reservarTurnoController',
                    label: "",
                    requiresLogin: true
                })
                .when('/institucion_1/:idInstitucion/prestador/:idPrestador/reservarTurno/:start/:duracion', {
                    templateUrl: './views/reservarTurno.paso2.html',
                    controller: 'reservarTurnoPaso2Controller',
                    label: "",
                    requiresLogin: true
                })
                .when('/institucion_1/:idInstitucion/prestador/:idPrestador/paciente/:idPaciente/turno/:start/:duracion/', {
                    templateUrl: './views/reservarTurnoResumen.html',
                    controller: 'reservarTurnoResumenController',
                    label: "",
                    requiresLogin: true
                })
                .when('/institucion_1/:idInstitucion/prestador/:idPrestador/paciente/:idPaciente/turno/:start/:duracion/estado/:estado', {
                    templateUrl: './views/reservarTurnoResumen.html',
                    controller: 'reservarTurnoResumenController',
                    label: "",
                    requiresLogin: true
                })
                .when('/logout', {
                    templateUrl: './views/logout.tpl.html',
                    controller: 'LogoutCtrl',
                    label: ""
                })
                .when('/misTurnos', {
                    templateUrl: './views/misTurnos.html',
                    controller: 'misTurnosController',
                    requiresLogin: true,
                    label: 'Mis turnos'
                })
                .when('/institucion/:idInstitucion/idPrestador/:idPrestador/altaTurno/:start/:duracion', {
                    templateUrl: './views/altaTurno.html',
                    controller: 'altaTurnoController',
                    label: "",
                    requiresLogin: true
                })
                .when('/registroPaciente', {
                    templateUrl: './views/registroPaciente.html',
                    controller: 'registroPacienteController',
                    label: ""
                }).when('/', {
                    templateUrl: './views/medicos.html',
                    controller: 'HomeController',
                    label: ""
                })/*comentado para que la pagina medicos sea la default en "/"
                    .when('/medicos', {
                    templateUrl: './views/medicos.html',
                    controller: 'HomeController',
                    label: ""
                })*/.when('/medicos/contacto/:origen', {
                    templateUrl: './views/medicos.html',
                    controller: 'HomeController',
                    label: ""
                }) //comentado para evitar que el home diriga al buscador y envie a /medicos - pagina comercial
                    .when('/buscador', {
                    templateUrl: './views/buscador.html',
                    controller: 'buscadorController',
                    label: ""
                 }).when('/auth0/redirect', {
                    templateUrl: './views/buscador.html',
                    controller: 'authRedirectController',
                    label: ""
                 }).when('/verified/:clientId', {
                    templateUrl: './views/buscador.html',
                    controller: 'buscadorController',
                    label: ""
                 }).when('/resultados', {
                    templateUrl: './views/resultadosBusqueda.html',
                    controller: 'resultadosBusquedaController',
                    label: ""
                }).when('/resultados/q/:query/lugar/:lugar', {
                    templateUrl: './views/resultadosBusqueda.html',
                    controller: 'resultadosBusquedaController',
                    label: ""
                }).when('/medicos/:idInstitucion/:idPrestador', {
                    templateUrl: './views/portadaPrestador.html',
                    controller: 'PortadaPrestadorController as vm',
                    label: ""
                }).when('/medicos/:alias', {
                    templateUrl: './views/portadaPrestador.html',
                    controller: 'PortadaPrestadorController as vm',
                    label: ""
                }).when('/turnosportables/:idInstitucion/:idPrestador/reservarturno/:start', {
                    templateUrl: './views/turnosPortables.html',
                    controller: 'turnosPortablesController as vm',
                    label: ""
                }).when('/conf/:idTurno', {
                    templateUrl: './views/confirmarTurno.html',
                    controller: 'confirmarTurnoController as vm',
                    label: ""
                }).when('/geocode', {
                    templateUrl: './views/geoCode.html',
                    controller: 'geoCodeController',
                    label: ""
                }).when('/geocode/:limit', {
                    templateUrl: './views/geoCode.html',
                    controller: 'geoCodeController',
                    label: ""
                }).when('/registroProfesional', {
                    templateUrl: './views/registroProfesional.html',
                    controller: 'registroProfesionalController',
                    label: ""
                }).when('/funcionalidades', {
                    templateUrl: './views/funcionalidades.html',
                    controller: 'HomeController',
                    label: ""
                }).when('/planes', {
                    templateUrl: './views/planes.html',
                    controller: 'HomeController',
                    label: ""
                });

        }]);
} ());