(function () {
    angular.module('TodoTurnosApp')
    .service('TodoTurnosApp.srvcDatosReferencia', ['$http', '$q', 'TodoTurnosAppConfig', function ($http, $q, config) {
        //API pública
        var o = {
            getParametros: function () {
                var defer = $q.defer();
                $http({ method: 'GET', url: config.urlRestServices + 'parametros/' })
                .then(function (response) {
                    defer.resolve(response.data);
                }, function (err) {
                    defer.reject(err);
                });
                return defer.promise;
            },
            getObrasSociales: function () {
                var defer = $q.defer();
                $http({ method: 'GET', url: config.urlRestServices + 'parametros/' + 'obrasSociales/' })
                .then(function (response) {
                    defer.resolve(response.data);
                }, function (err) {
                    defer.reject(err);
                });
                return defer.promise;
            },
        };
        return o;
    }]);
}());