(function () {
    angular.module('TodoTurnosApp')
        .controller('registroPacienteController',
        ['$scope'
            , 'srvcPacientes'
            , 'srvcSeguridad'
            , 'TodoTurnosAppConfig'
            , 'toastr'
            , 'TodoTurnosAppSession'
            , 'TodoTurnosAppUtils'
            , '$location'
            , '$timeout'
            , function ($scope, srvcPacientes, srvcSeguridad, config, toastr, session, utils, $location, $timeout) {

                var toArray = function (tipos) {
                    var arr = [];
                    angular.forEach(tipos, function (value, key) {
                        arr.push({ _id: key, codigo: value.codigo, descripcion: value.descripcion });
                    });
                    return arr;
                };

                var toArraySociales = function (obrasSociales) {
                    var arr = [];
                    angular.forEach(obrasSociales, function (value, key) {
                        arr.push({ _id: key, codigo: value.codigo, prepaga: value.prepaga, plan: value.plan });
                    });
                    return arr;
                };


                //validaciones
                $scope.isValid = function () {
                    return (!$scope.esVisualizacion && $scope.emailValido && $scope.nombreValido && $scope.documentoValido && $scope.isPisoValido && $scope.isDepartamentoValido
                        && (($scope.direccionSeleccionada && $scope.paciente_copy.direccion.altura) || !$scope.gdireccion));
                }
                $scope.setPisoValido = function () {
                    $scope.isPisoValido = $scope.paciente_copy.direccion.piso ? /^[A-Za-z\d\s]+$/.test($scope.paciente_copy.direccion.piso) : true;
                }
                $scope.setDepartamentoValido = function () {
                    $scope.isDepartamentoValido = $scope.paciente_copy.direccion.departamento ? /^[A-Za-z\d\s]+$/.test($scope.paciente_copy.direccion.departamento) : true;
                }
                $scope.validarDocumento = function () {
                    var dni = $scope.paciente_copy.numeroDocumento;
                    var regex = config.regExpressions.dni;
                    $scope.documentoValido = (dni && regex.test(dni));
                };
                $scope.validarNombre = function () {
                    var nombre = $scope.paciente_copy.nombre;
                    $scope.nombreValido = (nombre && nombre.length > 5);
                };

                $scope.validarEmail = function () {
                    var email = $scope.paciente_copy.email;
                    var regex = config.regExpressions.email;
                    $scope.emailValido = (email && regex.test(email));
                };
                $scope.validarDireccion = function () {
                    $scope.direccionSeleccionada = false;
                    if (($scope.gdireccion) && ($scope.paciente_copy.direccion.altura != 0))
                        $scope.sinAltura = false
                    else
                        $scope.sinAltura = true
                }

                $scope.setPisoValido = function () {
                    $scope.isPisoValido = $scope.paciente_copy.direccion.piso ? /^[A-Za-z\d\s]+$/.test($scope.paciente_copy.direccion.piso) : true;
                }
                $scope.setDepartamentoValido = function () {
                    $scope.isDepartamentoValido = $scope.paciente_copy.direccion.departamento ? /^[A-Za-z\d\s]+$/.test($scope.paciente_copy.direccion.departamento) : true;
                }

                $scope.goback = function () {
                    window.history.back();
                };

                $scope.clear = function () {
                    $scope.gdireccion = "";
                    $scope.validarDireccion();
                    $scope.sinAltura = false;
                    $scope.paciente_copy.direccion = { calle: "", provincia: "", ciudad: "", codigoPostal: "0", altura: "0", piso: "", departamento: "" };
                }

                getPrepagaIndexByName = function (nombre) {
                    for (var i = 0; i < $scope.obrasSociales.length; i++) {
                        if ($scope.obrasSociales[i].prepaga == nombre) {
                            return $scope.obrasSociales[i]._id;
                        }
                    }
                }

                $scope.placeChanged = function (place) {
                    if (!$scope.paciente)
                        $scope.paciente_copy = { direccion: { calle: "", provincia: "", ciudad: "", codigoPostal: "0", altura: "0", piso: "", departamento: "" } };
                    else
                        $scope.paciente_copy.direccion = { calle: "", provincia: "", ciudad: "", codigoPostal: "0", altura: "0", piso: "", departamento: "" };
                    for (i = 0; i < place.address_components.length; i++) {
                        if (place.address_components[i].types.indexOf("route") == 0)
                            $scope.paciente_copy.direccion.calle = place.address_components[i].short_name;
                        if (place.address_components[i].types.indexOf("administrative_area_level_1") == 0)
                            $scope.paciente_copy.direccion.provincia = place.address_components[i].short_name;
                        if (place.address_components[i].types.indexOf("locality") == 0)
                            $scope.paciente_copy.direccion.ciudad = place.address_components[i].short_name;
                        if (place.address_components[i].types.indexOf("postal_code") == 0)
                            $scope.paciente_copy.direccion.codigoPostal = place.address_components[i].short_name.match(/\d+/)[0];
                        if (place.address_components[i].types.indexOf("street_number") == 0)
                            $scope.paciente_copy.direccion.altura = place.address_components[i].short_name;
                    }
                    $scope.validarDireccion();
                    if (!$scope.sinAltura)
                        $scope.direccionSeleccionada = true;
                };

                $scope.persistPaciente = function () {
                    $scope.paciente_copy.estado = "P";//Paciente Efectivo
                    if ($scope.item) {
                        $scope.paciente_copy.obraSocial.prepaga = $scope.obrasSociales[$scope.item._id].prepaga;
                    }
                    if ($scope.item && $scope.item.selectedPlan)
                        $scope.paciente_copy.obraSocial.plan = $scope.item.selectedPlan;
                    srvcPacientes.insert($scope.paciente_copy).then(function (res) {
                        srvcSeguridad.linkPerfilUsuario(res);
                        toastr.success("Muchas gracias por registrarse en TodoTurnos.com.", config.toastrMensajeConfig);
                        var route = session.getUltimaRuta();
                        if (route && route != 'null') {
                            $location.path(route);
                            session.setUltimaRuta(null);
                        }
                        else {
                            $location.path('/');
                        }
                    }, function (err) {
                        if (err.data.exists)
                            toastr.error("Ya existe un paciente con el mismo Documento.", config.toastrErrorConfig);
                        else
                            toastr.error("Ha ocurrido un error con su registración. Por favor intentelo nuevamente en unos instantes. ", config.toastrErrorConfig);
                    });
                };

                //punto de entrada
                $scope.tiposDocumento = toArray(session.getTiposDocumento());
                $scope.sexos = toArray(session.getSexos());
                $scope.obrasSociales = toArraySociales(session.getObrasSociales());

                var usr = srvcSeguridad.getPerfilUsuario();
                $scope.paciente = { email: usr ? usr.profile.email : null, tipoDocumento: $scope.tiposDocumento[0], sexo: $scope.sexos[0], obraSocial: { prepaga: $scope.obrasSociales[0].prepaga, plan: $scope.obrasSociales[0].plan[0] }, direccion: { domicilio: "", provincia: "", ciudad: "", cp: "0", altura: "0", estado: 'P' } };
                $scope.validarDireccion();
                $scope.paciente_copy = utils.clone($scope.paciente)
                $scope.item = {
                    prepaga: $scope.paciente.obraSocial.prepaga,
                    _id: getPrepagaIndexByName($scope.paciente.obraSocial.prepaga),
                    selectedPlan: $scope.paciente_copy.obraSocial.plan
                };

                $scope.validarDocumento();
                $scope.validarEmail();
                $scope.validarNombre();
                $scope.direccionSeleccionada = true
                $scope.sinAltura = false
                $scope.isPisoValido = true;
                $scope.isDepartamentoValido = true;

            }]);

} ());