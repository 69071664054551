(function () {
    angular.module('TodoTurnosApp')
        .factory('authService', ['$rootScope', 'lock', 'authManager', '$location',
            function ($rootScope, lock, authManager, $location) {
                var o = {
                    login: function () {
                        lock.show();
                    },
                    registerAuthenticationListener: function () {
                        lock.on('authenticated', function (authResult) {
                            lock.getUserInfo(authResult.accessToken,
                                function (error, profile) {
                                    profile.user_metadata = undefined;
                                    authManager.authenticate();
                                    $rootScope.$emit('authenticated', { 'profile': profile, 'authResult': authResult });
                                }, function (err) {
                                });
                        });
                    },
                    isAuthenticated: authManager.isAuthenticated,
                    unAuthenticate: authManager.unauthenticate,
                    authenticate: function (profile, token) {
                        authManager.authenticate();
                        $rootScope.$emit('login', "seteado");
                    },
                    checkAuthOnRefresh: authManager.checkAuthOnRefresh
                };





                return o;
            }]);

} ());