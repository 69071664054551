(function () {
    angular.module('TodoTurnosApp')
        .constant('TodoTurnosAppConfig', {
            auth0ClientId: '8DvuZYr9W0QeNnKUMlZJNCwsVZJ9p3I6',
            googleMapsAPI: 'https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyAMEf3KsGW1f-TDI7txfRc9qYaXI8y53d0',
            urlRestServices: 'https://be.todoturnos.com/',
            urlFrontEnd: 'https://profesional.todoturnos.com/',
            toastrMensajeConfig: {//configuracion de toastr para mensajes que no sean de error
                closeButton: true,
                timeOut: 3000,
                preventDuplicates: true
            },
            toastrErrorConfig: {//configuracion de toastr para mensajes de error
                closeButton: true,
                timeOut: 5000,
                preventDuplicates: true
            },
            toastrMensajePersistente: {//configuracion de toastr para mensajes que no se cierran
                closeButton: false,
                extendedTimeOut: 0,
                preventDuplicates: true,
                tapToDismiss: false
            },
            regExpressions: {
                email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                dni: /^([A-Za-z\d\s]){6,10}$/,
                telefono: /^([A-Za-z\d\s]){8,14}$/,
                nombre: /[\sAa-zZ]*\s[A-Z].$|[A-Z].\s[Aa-zZ]+$|[\sAa-zZ]*\s[A-Z].([\sAa-zZ]*)$/i
            }

        });
}());