(function () {
    angular.module('TodoTurnosApp')
        .service('TodoTurnosAppSession', ['TodoTurnosApp.srvcDatosReferencia', function (srvcDatosReferencia) {
            var o = {
                setParametrosSistema: function () {
                    localStorage.removeItem('parametrosSistema');
                    //if (!localStorage.getItem('parametrosSistema')) {
                        srvcDatosReferencia.getParametros().then(function (res) {
                            localStorage.setItem('parametrosSistema', JSON.stringify(res));
                        }, function (err) {
                        });
                    //}
                },
                setObrasSociales: function () {
                    //if (!localStorage.getItem('obrasSociales')) {
                        srvcDatosReferencia.getObrasSociales().then(function (res) {
                            localStorage.setItem('obrasSociales', JSON.stringify(res));
                        }, function (err) {
                        });
                    //}
                },
                getRoles: function () {
                    return JSON.parse(localStorage.getItem('parametrosSistema')).rol;
                },
                getEstadosTurno: function () {
                    return JSON.parse(localStorage.getItem('parametrosSistema')).estadoTurno;
                },
                getTiposDocumento: function () {
                    return JSON.parse(localStorage.getItem('parametrosSistema')).tipoDocumento;
                },
                getSexos: function () {
                    return JSON.parse(localStorage.getItem('parametrosSistema')).sexo;
                },
                getObrasSociales: function () {
                    return JSON.parse(localStorage.getItem('obrasSociales'));
                },
                setTurnoSeleccionado: function (turno) {
                    localStorage.setItem('turnoSeleccionado', JSON.stringify(turno));
                },
                getTurnoSeleccionado: function () {
                    return JSON.parse(localStorage.getItem('turnoSeleccionado'));
                },
                setPrestadorSeleccionado: function (prestador) {
                    localStorage.setItem('prestadorSeleccionado', JSON.stringify(prestador));
                },
                getPrestadorSeleccionado: function () {
                    return JSON.parse(localStorage.getItem('prestadorSeleccionado'));
                },
                setUltimaRuta: function (route) {
                    localStorage.setItem('ultimaRuta', route);
                },
                getUltimaRuta: function () {
                    return localStorage.getItem('ultimaRuta');
                },
                setInstitucionSeleccionada: function (inst) {
                    localStorage.setItem('institucionSeleccionada', JSON.stringify(inst));
                },
                getInstitucionSeleccionada: function () {
                    return JSON.parse(localStorage.getItem('institucionSeleccionada'));
                },
                setProfile: function (prfl) {
                    localStorage.setItem('profile', JSON.stringify(prfl));
                },
                getProfile: function () {
                    return JSON.parse(localStorage.getItem('profile'));
                },

                setToken: function (token) {
                    localStorage.setItem('token', token);
                },
                getToken: function () {
                    return localStorage.getItem('token');
                },

                setAccessToken: function (token) {
                    localStorage.setItem('accessToken', token);
                },
                getAccessToken: function () {
                    return localStorage.getItem('accessToken');
                }




            };
            return o;
        }]);
} ());
