(function () {
    angular.module('TodoTurnosApp')
        .controller('turnoOkController',
        ['$scope'
            , '$routeParams'
            , 'TodoTurnosApp.config'
            , '$location'
            , '$uibModalInstance'
            , 'parTurno'
            , 'parPrestador'
            , function ($scope, $routeParams, config, $location, $uibModalInstance, parTurno, parPrestador) {
                
                $scope.turno = parTurno;
                $scope.prestador = parPrestador;

                $scope.goback = function () {
                    $location.path('/institucion/' + $scope.turno.idInstitucion);
                    $uibModalInstance.close();
                }

            }]);
    // .filter('dateToISO', function () {
    // return function (input) {
    //     return new Date(input);
    // };
    // });
} ());