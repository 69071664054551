(function () {
    angular.module('TodoTurnosApp').controller('misTurnosController', ['$scope', '$routeParams', 'toastr', 'TodoTurnosAppSession', '$compile', '$location', 'srvcPrestadores', 'srvcPacientes', 'srvcSeguridad', 'TodoTurnosAppSession', 'TodoTurnosAppUtils', 'TodoTurnosAppConfig',
        function ($scope, $routeParams, toastr, TodoTurnosAppSession, $compile, $location, srvcPrestadores, srvcPacientes, srvcSeguridad, session, utils, config) {

            var toArray = function (tipos) {
                var arr = [];
                angular.forEach(tipos, function (value, key) {
                    arr.push({ _id: key, codigo: value.codigo, descripcion: value.descripcion });
                });
                return arr;
            };

            var toArraySociales = function (obrasSociales) {
                var arr = [];
                angular.forEach(obrasSociales, function (value, key) {
                    arr.push({ _id: key, codigo: value.codigo, prepaga: value.prepaga, plan: value.plan });
                });
                return arr;
            };

            $scope.isValid = function () {
                // return ($scope.nombreValido);
                return true;
            };

            $scope.setPisoValido = function () {
                $scope.isPisoValido = $scope.paciente_copy.direccion.piso ? /^[A-Za-z\d\s]+$/.test($scope.paciente_copy.direccion.piso) : true;
            };
            $scope.setDepartamentoValido = function () {
                $scope.isDepartamentoValido = $scope.paciente_copy.direccion.departamento ? /^[A-Za-z\d\s]+$/.test($scope.paciente_copy.direccion.departamento) : true;
            };
            $scope.validarDocumento = function () {
                var dni = $scope.paciente_copy.numeroDocumento;
                var regex = config.regExpressions.dni;
                $scope.documentoValido = (dni && regex.test(dni));
            };
            $scope.validarNombre = function () {
                var nombre = $scope.paciente_copy.nombre;
                $scope.nombreValido = (nombre && nombre.length > 5);
            };

            $scope.validarEmail = function () {
                var email = $scope.paciente_copy.email;
                var regex = config.regExpressions.email;
                $scope.emailValido = (email && regex.test(email));
            };

            var getPrepagaIndexByName = function (nombre) {
                for (var i = 0; i < $scope.obrasSociales.length; i++) {
                    if ($scope.obrasSociales[i].prepaga == nombre) {
                        return $scope.obrasSociales[i]._id;
                    }
                }
                //   return 0;
            };

            $scope.persistPaciente = function () {
                $scope.item ? $scope.paciente_copy.obraSocial.prepaga = $scope.item.prepaga : $scope.paciente_copy.obraSocial = null;
                $scope.paciente_copy.obraSocial.plan = null;
                if ($scope.selectedPlan && $scope.item)
                    $scope.paciente_copy.obraSocial.plan = $scope.selectedPlan;
                srvcPacientes.update($scope.paciente_copy).then(function (res) {
                    // srvcSeguridad.linkPerfilUsuario(res);
                    toastr.success("Sus datos han sido guardados con éxito", config.toastrMensajeConfig);
                }, function (err) {
                    toastr.error("Ha ocurrido un error al guardar. Por favor intentelo nuevamente en unos instantes. ", config.toastrErrorConfig);
                });
            };

            var abrirDetalle = function (turno) {
            };

            $scope.formatearFecha = function (fecha_p) {
                fecha = new Date(fecha_p.replace('Z', '-03:00'));
                return (moment(fecha).format('dddd L, h:mm a'));
            };

            $scope.esMenorAFechaActual = function (item) {
                fechaTurno = new Date(item.fechaHoraInicio);
                fechaActual = new Date();

                return (fechaTurno < fechaActual);
            };

            $scope.seleccionarTurno = function (turno) {
                $scope.turnoSeleccionado = turno;
            };

            $scope.getFamilia = function () {
                srvcPacientes.getFamiliaByEmail($scope.paciente.email)
                    .then(function (pacientes) {
                    });
            };

            $scope.puedeCancelar = function (idEstado) {
                return ($scope.estadosTurnos[idEstado].codigo == 'S' || $scope.estadosTurnos[idEstado].codigo == 'C');
            };

            $scope.getColorEstado = function (estadoTurno) {
                var estado = $scope.estadosTurnos[estadoTurno].codigo;
                var retorno;
                switch (estado) {
                    case "S":
                        retorno = "yellow"
                    case "C":
                        retorno = "green"
                    default:
                        retorno = "red"
                }
                return retorno;
            };

            $scope.getDescripcionEstado = function (estadoTurno) {
                return $scope.estadosTurnos[estadoTurno].descripcion;
            };

            var estadosToArray = function (estados) {
                var arr = [];
                angular.forEach(estados, function (value, key) {
                    arr.push({ _id: key, codigo: value.codigo, descripcion: value.descripcion });
                });
                return arr;
            };

            $scope.obtenerEstadoByCodigo = function (codigo) {
                for (var i = 0; i < $scope.arrayEstadosTurnos.length; i++) {
                    if ($scope.arrayEstadosTurnos[i].codigo == codigo) {
                        return $scope.arrayEstadosTurnos[i]._id;
                    }
                }
            };

            $scope.cancelarTurno = function (turno) {
                var idEstado = $scope.obtenerEstadoByCodigo("A");
                turno.estado = idEstado;
                srvcPacientes.upsertTurno(turno, null);
            };

            var completarPrestador = function (j) {
                srvcPrestadores.getPrestador($scope.turnos[j].idInstitucion, $scope.turnos[j].idPrestador)
                    .then(function (prestador) {
                        if (prestador[0]) {
                            $scope.turnos[j].nombrePrestador = prestador[0].prestadores[0].perfil.nombre;
                            $scope.turnos[j].nombreProfesion = prestador[0].prestadores[0].perfil.profesiones[0].nombre;
                        }
                    }, function (err) {
                    });
            };

            $scope.porEstado = function (estado) {
                return function (turno) {
                    return $scope.estadosTurnos[turno.estado].codigo == estado;
                }
            };

            $scope.estadoTurno = function (turno, estado) {
                return (turno.estado == estado);
            }

            var completarInstitucion = function (j) {
                srvcPrestadores.getInstitucion($scope.turnos[j].idInstitucion)
                    .then(function (institucion) {
                        if (institucion) {
                            $scope.turnos[j].nombreInstitucion = institucion.razonSocial;
                            if (institucion.direccion)
                                $scope.turnos[j].direccion = institucion.direccion.domicilio + ' ' +
                                    institucion.direccion.altura + ', ' + institucion.direccion.ciudad;
                            if (institucion.telefonos) {
                                $scope.turnos[j].telefonos = institucion.telefonos[0];
                                $scope.turnos[j].telefonos += institucion.telefonos[1] ? institucion.telefonos[1] : '';
                            }
                        }
                    })
            }

            var completarPacientes = function (j) {
                srvcPacientes.getPacienteById($scope.turnos[j].idPaciente)
                    .then(function (paciente) {
                        if (paciente)
                            $scope.turnos[j].nombrePaciente = paciente.nombre;
                    })
            }

            var agregarTurnos = function (turnos) {
                $scope.turnos.push.apply($scope.turnos, turnos);
            }

            var obtenerTurnosPacientes = function (pacientes) {
                for (i = 0; i < pacientes.length; i++) {
                    srvcPacientes.getTurnosByPaciente(pacientes[i]._id)
                        .then(function (turnos) {
                            agregarTurnos(turnos);
                        })
                        .then(function () {
                            for (j = 0; j < $scope.turnos.length; j++) {
                                completarPacientes(j)
                            }
                        })
                        .then(function () {
                            for (j = 0; j < $scope.turnos.length; j++) {
                                completarPrestador(j);
                            }
                        })
                        .then(function () {
                            for (j = 0; j < $scope.turnos.length; j++) {
                                completarInstitucion(j);
                            }
                        });;
                }
            }

            $scope.esFamiliar = function (turno) {
                return ($scope.perfilUsuarioLogueado.profile.metadata._id != turno.idPaciente);
            }

            $scope.limpiarPrepaga = function () {
                $scope.item.prepaga = getPrepagaIndexByName("N/A");
                $scope.selectedPlan = "";
            }

            $scope.activarTab = function (tab) {
                $(".group li").removeClass("current_page_item");
                $('#' + tab).addClass("current_page_item");

                if ($magicLine) {
                    $magicLine
                        .data("origLeft", $('#' + tab).position().left)
                        .data("origWidth", $('#' + tab).width());
                }

                $scope.tabActiva = tab;
            }

            $scope.esTabActiva = function (tab) {
                return ($scope.tabActiva == tab);
            }
            //Punto de entrada

            var j = 0;
            $scope.activarTab('turnos');
            $scope.panel = true;
            $scope.turnos = [];
            $scope.estadosTurnos = TodoTurnosAppSession.getEstadosTurno();
            $scope.arrayEstadosTurnos = estadosToArray($scope.estadosTurnos);
            var tDocs = TodoTurnosAppSession.getTiposDocumento();
            $scope.tiposDocumento = toArray(tDocs);
            $scope.sexos = toArray(TodoTurnosAppSession.getSexos());
            $scope.obrasSociales = toArraySociales(TodoTurnosAppSession.getObrasSociales());
            $scope.item = {};
            $scope.paciente_copy = {};


            // var pacienteEmail = store.get('profile')?store.get('profile').email.toUpperCase():null; 
            $scope.perfilUsuarioLogueado = srvcSeguridad.getPerfilUsuario();
            if ($scope.perfilUsuarioLogueado) {
                srvcPacientes.getPacienteById($scope.perfilUsuarioLogueado.profile.metadata._id)
                    .then(function (paciente) {
                        $scope.paciente_copy = angular.copy(paciente);
                        $scope.paciente_copy.esParticular = !(paciente.obraSocial.prepaga && paciente.obraSocial.plan);
                        $scope.item.prepaga = paciente.obraSocial.prepaga;
                        $scope.item._id = getPrepagaIndexByName($scope.item.prepaga);
                        $scope.selectedPlan = paciente.obraSocial.plan;
                    })
                    .then(function () {
                        srvcPacientes.getFamiliaByEmail($scope.perfilUsuarioLogueado.profile.metadata.email)
                            .then(function (pacientes) {
                                obtenerTurnosPacientes(pacientes);
                            });
                    })

            }
            else {
                //Debería redirigir a login
            }

            //Menu slider
            var $el, leftPos, newWidth,
                $mainNav = $("#example-one");

            $mainNav.append("<li id='magic-line'></li>");
            var $magicLine = $("#magic-line");

            $magicLine
                .width($(".current_page_item").width())
                .css("left", $(".current_page_item a").position().left)
                .data("origLeft", $magicLine.position().left)
                .data("origWidth", $magicLine.width());

            $("#example-one li a").hover(function () {
                $el = $(this);
                leftPos = $el.position().left;
                newWidth = $el.parent().width();
                $magicLine.stop().animate({
                    left: leftPos,
                    width: newWidth
                });
            }, function () {
                $magicLine.stop().animate({
                    left: $magicLine.data("origLeft"),
                    width: $magicLine.data("origWidth")
                });
            });

        }]);
} ()); 