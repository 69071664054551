(function () {
    angular.module('TodoTurnosApp').controller('homeInstitucionController', [
        '$scope', 
        '$routeParams',
        '$compile', 
        'srvcPrestadores', 
        'TodoTurnosAppSession', 
        'uiCalendarConfig', 
        'TodoTurnosAppUtils',
        '$location',
        function ($scope, $routeParams, $compile, srvcPrestadores, session, uiCalendarConfig, utils, $location) {

            //===============================================================================
            /*funciones para el control de preview de calendario*/
            //===============================================================================

            $scope.dateChange = function () {
                $scope.setCurrentDate('calendario', $scope.dt);
            };

            $scope.today = function () {
                $scope.dt = new Date();
            };
            $scope.today();

            $scope.options = {
                customClass: getDayClass,
                minDate: new Date(),
                showWeeks: true,
                minMode: 'day',
                maxMode: 'day'
            };

            $scope.toggleMin = function () {
                $scope.options.minDate = $scope.options.minDate ? null : new Date();
            };

            $scope.toggleMin();

            $scope.setDate = function (year, month, day) {
                $scope.dt = new Date(year, month, day);
            };
            $scope.dateChange = function () {
                $scope.setCurrentDate('calendario', $scope.dt);
            };

            function getDayClass(data) {
                var dia = utils.getComienzoDia(data.date).getTime();
                if (!$scope.porcentajesOcupacion[dia]) {
                    if (!$scope.fetchingPorcentajes) {
                        var start = new Date(dia);
                        var end = new Date(dia + 42 * 24 * 3600000);
                        $scope.getPorcentajesOcupacion(start, end);
                    }
                }
                return $scope.porcentajesOcupacion[dia];
            };

            $scope.getPorcentajesOcupacion = function (pstart, pend) {
                var porcentajesOcupacion = [];
                var calculatePorcentajeDia = function (turnosDia, disposDia) {
                    var _start;
                    var step;
                    var totalSteps = 0;
                    var totalStepsOcupados = 0;
                    if (!disposDia)
                        return 'porcentaje_10';
                    if (!turnosDia)
                        return 'porcentaje_0';
                    for (var i = 0; i < disposDia.length; i++) {
                        var dispo = disposDia[i];
                        var startDispo = new Date(dispo.fechaHoraInicio.replace('Z', '-03:00')).getTime();
                        var endDispo = new Date(dispo.fechaHoraFin.replace('Z', '-03:00')).getTime();
                        _start = startDispo;
                        while (_start < endDispo) {
                            totalSteps++;
                            step = {
                                start: _start,
                                end: _start + $scope.step * 60000
                            };
                            for (var j = 0; j < turnosDia.length; j++) {
                                var startTurno = new Date(turnosDia[j].fechaHoraInicio.replace('Z', '-03:00')).getTime();
                                var endTurno = new Date(turnosDia[j].fechaHoraFin.replace('Z', '-03:00')).getTime();
                                if ((step.start >= startTurno && step.start < endTurno) || (step.end > startTurno && step.end <= endTurno)) {
                                    totalStepsOcupados++;
                                    break;
                                }
                            }
                            _start += $scope.step * 60000;
                        }
                    }
                    return 'porcentaje_' + Math.ceil((100 - (((totalStepsOcupados / totalSteps) * 100) * (-1 / 2) + 100)) / 5).toString();
                };
                var groupTurnosPorFecha = function (turnos) {
                    var grupos = [];
                    for (var i = 0; i < turnos.length; i++) {
                        var dia = utils.getComienzoDia(new Date(turnos[i].fechaHoraInicio.replace('Z', '-03:00'))).getTime();
                        if (!grupos[dia])
                            grupos[dia] = [turnos[i]];
                        else
                            grupos[dia].push(turnos[i]);
                    }
                    return grupos;
                };
                var calculatePorcentajes = function (start, end, turnos, dispos) {
                    var startMls = start.getTime();
                    var endMls = end.getTime();
                    var turnosGrp = groupTurnosPorFecha(turnos);
                    var disposGrp = groupTurnosPorFecha(dispos);
                    var mls = startMls;
                    while (mls < endMls) {
                        porcentajesOcupacion[mls] = calculatePorcentajeDia(turnosGrp[mls], disposGrp[mls]);
                        mls += 24 * 3600000;
                    }
                    return porcentajesOcupacion;
                };
                var loadPorcentajesOcupacion = function (porcentajes) {
                    if (!$scope.porcentajesOcupacion)
                        $scope.porcentajesOcupacion = [];
                    for (var key in porcentajes) {
                        if (porcentajes.hasOwnProperty(key)) {
                            $scope.porcentajesOcupacion[key] = porcentajes[key];
                        }
                    }

                };
                var startDt = pstart
                var start = startDt.toLocalISOString();
                var endDt = pend;
                var end = endDt.toLocalISOString();
                var props;
                if ($scope.idPrestador == undefined) {
                    return;
                }
                $scope.fetchingPorcentajes = true;
                srvcPrestadores.getTurnosAbiertosEntreFechas($scope.idInstitucion, $scope.idPrestador, start, end).then(function (res) {
                    srvcPrestadores.getDisponibilidadEntreFechas($scope.idInstitucion, $scope.idPrestador, start, end).then(function (resDisp) {
                        loadPorcentajesOcupacion(calculatePorcentajes(startDt, endDt, res, resDisp));
                        $scope.fetchingPorcentajes = false;
                        $scope.$broadcast('refreshDatepickers');
                    });
                }, function (err) {
                });
            };
            //===============================================================================
            //===============================================================================

            var setPrestadoresFlgs = function () {
                for (var i = 0; i < $scope.institucion.prestadores.length; i++) {
                    var k = $scope.institucion.prestadores[i].prestador_id;
                    if ($scope.idPrestador == undefined)
                        $scope.flgsPrestadores[k] = true;
                    else {
                        $scope.flgsPrestadores[k] = false;
                        if ($scope.idPrestador == k) {
                            $scope.flgsPrestadores[k] = true;
                        }
                    }
                }
            };

            var setPrestadoresSeleccionadosFlgs = function () {
                for (var i = 0; i < $scope.institucion.prestadores.length; i++) {
                    var k = $scope.institucion.prestadores[i].prestador_id;
                    if ($scope.idPrestador == undefined)
                        $scope.flgsPrestadoresSeleccionados[k] = false;
                    else {
                        $scope.flgsPrestadoresSeleccionados[k] = false;
                        if ($scope.idPrestador == k) {
                            $scope.flgsPrestadoresSeleccionados[k] = true;
                        }
                    }
                }
            };

            var isPrestador = function (item) {
                var rol = $scope.rolesDicc[item.seguridad.idRol];
                return rol.codigo.toLowerCase().indexOf('prestador') >= 0;
            };

            var getPrestadores = function (institucion) {
                var prestadores = [];
                for (var i = 0; i < institucion.prestadores.length; i++) {
                    if (isPrestador(institucion.prestadores[i]))
                        prestadores.push(institucion.prestadores[i]);
                }
                return prestadores;
            };

            var renderTurno = function (turnoDb) {
                
                var turnoUI = {
                    start: turnoDb.fechaHoraInicio,
                    end: turnoDb.fechaHoraFin,
                    className: 'eventoL'
                };
                return turnoUI;
            };

            var isTurnoCerrado = function (turno) {
                return !($scope.estados[turno.turnoDb.estado].codigo == 'S' || $scope.estados[turno.turnoDb.estado].codigo == 'C');
            };

            $scope.applyRefresh = function () {
                uiCalendarConfig.calendars['calendario'].fullCalendar('refetchEvents');
            };

            $scope.setCurrentDate = function (calendar, date) {
                uiCalendarConfig.calendars[calendar].fullCalendar('gotoDate', date);
            };

            $scope.calendarCtrl = function () {
                $scope.eventSources = [];

                var touches = null;
                window.document.addEventListener('touchstart', function (e) {
                    touches = event.touches[0];
                });

                /* Change View */
                $scope.renderCalendar = function (calendar) {
                    setTimeout(function () {
                        if (uiCalendarConfig.calendars[calendar]) {
                            uiCalendarConfig.calendars[calendar].fullCalendar('render');
                            uiCalendarConfig.calendars[calendar].fullCalendar('changeView', 'basicWeek');
                            uiCalendarConfig.calendars[calendar].fullCalendar('gotoDate', new Date());
                            uiCalendarConfig.calendars[calendar].fullCalendar('render');
                            $('.fc-month-button').hide();
                            $scope.$apply();
                        }
                    });
                };
                $scope.eventRender = function (event, element, view) {
                   
                    
                     $compile(element)($scope);

                };
                /* alert on eventClick */
                $scope.onEventClick = function (pevent, jsEvent, view, p3, p4, p5) {
                    openAltaTurno(pevent);
                };

                

                var openAltaTurno = function (turno) {
                    $location.path($location.path() + '/idPrestador/'+$scope.idPrestador+'/altaTurno/'+turno.start._d+'/'+$scope.step);
                };
                
                /* config object */
                $scope.uiConfig = {
                    calendar: {
                        events: function (start, end, timezone, callback) {
                            // if (!$scope.idPrestador)
                            if ($scope.idPrestador == undefined)
                                return;
                            var startDt = new Date().toISOString();
                            var endDt = new Date(end).toISOString();
                            srvcPrestadores.getHorariosLibres($scope.idInstitucion, $scope.idPrestador, $scope.step, startDt, endDt).then(function (horarios) {
                                var turnos = [];
                                angular.forEach(horarios, function (val, key) {
                                    var evt = renderTurno(val);
                                    turnos.push(evt);
                                });
                                callback(turnos);
                            }, function (err) {
                                toastr.error("Ha ocurrido un error al obtener la disponibilidad del prestador. Por favor intente nuevamente más tarde.", config.toastrErrorConfig);
                            });
                        },
                        height: 450,
                        editable: true,
                        timezone: false,
                        scrollTime: '{0}:00:00'.replace('{0}', new Date().getHours()),
                        header: {
                            left: 'title',
                            center: 'basicDay basicWeek',
                            right: 'today prev,next'
                        },
                        slotDuration: $scope.prestador && $scope.prestador.datosAgenda ?
                            '00:{00}:00'.replace('{00}', $scope.prestador.datosAgenda.duracionTurnoMin)
                            : '00:15:00',
                        dayNames: ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sábado"],
                        dayNamesShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
                        monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
                        monthNamesShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
                        eventRender: $scope.eventRender,
                        allDaySlot: false,
                        eventClick: $scope.onEventClick,
                    }
                };
            };

            $scope.selectPrestador = function (prestador) {
                if (prestador) {
                    $scope.idPrestador = prestador.prestador_id;
                    $scope.showPrestador = true;
                    $scope.prestadorNombre = prestador.perfil.nombre;
                    $scope.step = prestador.datosAgenda ? (prestador.datosAgenda.duracionTurnoMin ? prestador.datosAgenda.duracionTurnoMin : 15) : 15;
                    $scope.applyRefresh();
                    $scope.$broadcast('refreshDatepickers');
                    $scope.renderCalendar('calendario');
                    session.setPrestadorSeleccionado(prestador);
                }
                else{
                    $scope.showPrestador = false;
                    $scope.idPrestador = $scope.prestadorNombre = $scope.step = undefined;
                }
                $scope.porcentajesOcupacion = [];
                setPrestadoresFlgs();
                setPrestadoresSeleccionadosFlgs();
            };

            //Punto de entrada
            $scope.flgsPrestadores = [];
            $scope.flgsPrestadoresSeleccionados = [];
            $scope.porcentajesOcupacion = [];
            $scope.rolesDicc = session.getRoles();
            $scope.eventSources = [];
            $scope.estados = session.getEstadosTurno();
            $scope.idInstitucion = $routeParams.idInstitucion;
            $scope.calendarCtrl();
            srvcPrestadores.getInstitucion($scope.idInstitucion)
                .then(function (institucion) {
                    $scope.institucion = institucion;
                    $scope.institucion.prestadores = getPrestadores($scope.institucion);
                    setPrestadoresFlgs();
                    setPrestadoresSeleccionadosFlgs();
                    $scope.calendarCtrl();
                });
        }]);
} ());