(function () {
    'use strict';
    angular.module('TodoTurnosApp')
        .controller('preLoginController',

        ['$scope',
            '$location',
            '$uibModalInstance',
            '$window',
            'TodoTurnosAppConfig',
            'appClientId',


            function ($scope, $location, $uibModalInstance, $window, config, appClientId) {
                
                $scope.showPaciente = false;
                $scope.showPrestador = false;
                if (appClientId) {
                    
                    if (appClientId.clientId == config.auth0ClientId) {
                        $scope.showPaciente = true;
                    }
                    else {
                        $scope.showPrestador = true;
                    }
                }
                else {
                    $scope.showPaciente = true;
                    $scope.showPrestador = true;
                }

                $scope.clickPaciente = function () {
                    $uibModalInstance.close();
                    $location.path("/login");
                }

                $scope.clickProfesional = function () {
                    
                    $window.location.href = 'https://profesional.todoturnos.com';
                }



                $scope.ok = function () {
                    $uibModalInstance.close();
                };

            }]);

})();