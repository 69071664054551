(function () {
    angular.module('TodoTurnosApp')
        .directive('confirmOnExit', function (TodoTurnosAppSession, TodoTurnosAppUtils, $rootScope) {
            return {
                link: function ($scope, elem, attrs) {
                    window.onbeforeunload = function () {
                        if (!$scope.isDirty) return;
                        if ($scope.isDirty()) {
                            return 'Si abandona la página actual, se perderán todos los cambios efectuados. ¿Desea continuar?';
                        }
                    }
                    var $locationChangeStartUnbind = $scope.$on('$routeChangeStart', function (event, next, current) {
                        if (!$scope.isDirty) return;
                        if ($scope.isDirty()) {
                            if (!confirm('Si abandona la página actual, se perderán todos los cambios efectuados. ¿Desea continuar?')) {
                                event.preventDefault();
                                current = current || next;
                                var _next = next.$$route;
                                var _curr = current.$$route;
                                var routeNext = TodoTurnosAppUtils.buildPathFromRoute(next);
                                var routeCurr = TodoTurnosAppUtils.buildPathFromRoute(current);
                                //Algunas veces se ejecutan dos navegaciones para una misma ruta...ver por que...
                                if (routeNext != routeCurr && _next.label) {
                                    $rootScope.preventPush = false;
                                    TodoTurnosAppSession.pushRoute({ name: _curr.label, url: routeCurr });
                                }
                            }

                        }
                    });
                    $scope.$on('$destroy', function () {

                        window.onbeforeunload = null;
                        $locationChangeStartUnbind();
                    });
                }
            };
        })
    .directive('googleplace', function () {
        return {
            require: 'ngModel',
            scope: {
                ngModel: '=',
                details: '=?'
            },
            link: function (scope, element, attrs, model) {
                var options = {
                    //types: ['(cities)'],
                    types: ['geocode'],
                    componentRestrictions: { country: 'ar' }
                };
                scope.gPlace = new google.maps.places.Autocomplete(element[0], options);
                google.maps.event.addListener(scope.gPlace, 'place_changed', function () {
                    scope.$apply(function () {
                        scope.details = scope.gPlace.getPlace();
                        model.$setViewValue(element.val());
                        if (scope.$parent)
                            if (scope.$parent.placeChanged)
                                scope.$parent.placeChanged(scope.details);
                    });
                });
            }
        };
    })

}());