(function () {
    'use strict';

    angular.module('TodoTurnosApp')
        .controller('PortadaPrestadorController', PortadaPrestadorController);

    PortadaPrestadorController.$inject = [
        '$scope',
        '$routeParams',
        'srvcPrestadores',
        '$location',
        '$uibModal'
    ];

    function PortadaPrestadorController($scope, $routeParams, srvcPrestadores, $location, $uibModal) {

        $scope.ok = function () {
            $uibModalInstance.close($ctrl.selected.item);
        };
        $scope.openTurno = function () {
            $document.scrollTopAnimated(400).then(function () {
            });

            var someElement = angular.element(document.getElementById('some-id'));
            $document.scrollToElementAnimated(someElement);
        }

        var getPrestador = function (institucion, idPrestador) {
            for (var i = 0; i < institucion.prestadores.length; i++) {
                if (institucion.prestadores[i].prestador_id == idPrestador)
                    return institucion.prestadores[i];
            }
            return null;
        };

        $scope.open = function (item) {
            var modalInstance = $uibModal.open({
                animation: $scope.animationsEnabled,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: './views/modals/showInfo.html',
                controller: 'showInfoController',
                size: 'md',
                resolve: {
                    prestador: function () {
                        return $scope.prestador;
                    },
                    tel: function () {
                        return $scope.institucion.telefonos;
                    }
                }
            });
        }
        $scope.agregarMapa = function () {
            $scope.mapProp = {
                // center: new google.maps.LatLng($scope.institucion.direccion.coord[0], $scope.institucion.direccion.coord[1]),
                zoom: 15,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                mapTypeControlOptions: {
                    mapTypeIds: ['Styled']
                },
                disableDefaultUI: true,
                // mapTypeId: 'Styled'
            };
        }
        $scope.openConsultorio = function (id) {
            $location.path('/consultorio/' + id);
        }

        $scope.init = function () {
            var styles = [
                {
                    featureType: "all",
                    stylers: [
                        { saturation: -80 }
                    ]
                }, {
                    featureType: "road.arterial",
                    elementType: "geometry",
                    stylers: [
                        { hue: "#00ffee" },
                        { saturation: 50 }
                    ]
                }, {
                    featureType: "poi.business",
                    elementType: "labels",
                    stylers: [
                        { visibility: "off" }
                    ]
                }
            ];
            google.maps.event.addDomListener(window, 'load', $scope.agregarMapa());
            $scope.map = new google.maps.Map(document.getElementById("googleMap"), $scope.mapProp);
            var styledMapType = new google.maps.StyledMapType(styles, { name: 'Styled' });
            $scope.map.mapTypes.set('Styled', styledMapType);
        }

        $scope.setMarker = function (coords) {

            var marker = new google.maps.Marker({
                position: coords,
                map: $scope.map,
                title: 'Usted está aquí!',
                icon: $scope.image
            });
            marker.setMap($scope.map);
        }


        //funciones


        $scope.openCalendar = function () {
            $('#calendario').animatescroll();
        }
        $scope.openComentarios = function () {
            $('#comentarios').animatescroll();
        }

        var centerMap = function (center) {
            google.maps.event.addListenerOnce($scope.map, "idle", function () {
                google.maps.event.trigger($scope.map, "resize");
                $scope.map.setCenter(center);
            });
        };

        //variables
        $scope.calendar = true;
        var alias = $routeParams.alias;
        $scope.alias = alias.split("-")[alias.split("-").length - 1];
        $scope.image = 'resources/images/marker.png';

        srvcPrestadores.getPrestadorByAlias($scope.alias).then(function (res) {



            $scope.idInstitucion = res[0]._id;
            $scope.idPrestador = res[0].prestadores[0].prestador_id;
            $scope.institucion = res[0];

            var center = new google.maps.LatLng($scope.institucion.direccion.coord[0], $scope.institucion.direccion.coord[1]);

            $scope.init();
            $scope.setMarker(center);
            centerMap(center);



            $scope.prestador = getPrestador($scope.institucion, $scope.idPrestador);
            srvcPrestadores.getConsultoriosByEmail($scope.prestador.perfil.email)
                .then(function (consultorios) {

                    $scope.consultorios = consultorios;
                    google.maps.event.trigger($scope.map, "resize");


                });
        });



        $scope.consultorios = [];



    };
})();