(function () {
    angular.module('TodoTurnosApp')
        .controller('resultadosBusquedaController', ['$routeParams', '$scope', 'srvcPrestadores', '$location', 'TodoTurnosAppSession', 'srvcBusqueda', '$filter',
            function ($routeParams, $scope, srvcPrestadores, $location, session, srvcBusqueda, $filter) {


                $scope.getLink = function(prestador){
                    return sinAcentos(replaceAll(prestador.nombre, ' ', '-')) + '-' + prestador.alias;
                }
                $scope.agregarMapa = function () {

                    $scope.mapProp = {
                        mapTypeControlOptions: {
                            mapTypeIds: ['Styled']
                        },
                        disableDefaultUI: true,
                        zoomControl: true,
                        // mapTypeId: 'Styled'
                    };
                }

                $scope.init = function () {
                    var styles = [
                        {
                            featureType: "all",
                            stylers: [
                                { saturation: -60 }
                            ]
                        }, {
                            featureType: "poi.park",
                            elementType: "geometry",
                            stylers: [
                                {
                                    hue: "#05ff03"
                                }
                            ]
                        }, {
                            featureType: "road.arterial",
                            elementType: "geometry",
                            stylers: [
                                { hue: "#00ffee" },
                                { saturation: 100 }
                            ]
                        }, {
                            featureType: "poi.business",
                            elementType: "labels",
                            stylers: [
                                { visibility: "off" }
                            ]
                        }
                    ];

                    google.maps.event.addDomListener(window, 'load', $scope.agregarMapa());
                    $scope.map = new google.maps.Map(document.getElementById("googleMap"), $scope.mapProp);
                    var styledMapType = new google.maps.StyledMapType(styles, { name: 'Styled' });
                    $scope.map.mapTypes.set('Styled', styledMapType);
                    $scope.image = 'resources/images/marker.png';
                    var infowindow = new google.maps.InfoWindow();
                }

                $scope.actualizarMapa = function (prestador) {
                    var marker, i, lat, long;
                    $scope.listaInstituciones = [];
                    //itero prestadores e instituciones de cada prestador
                    for (inst = 0; inst < prestador.instituciones.length; inst++) {
                        lat = prestador.instituciones[inst].direccion.coord[0];
                        long = prestador.instituciones[inst].direccion.coord[1];
                        //agrego lat y long al langlist de cada institucion para calcular centro y zoom
                        if (lat && long) {
                            $scope.LatLngList.push(new google.maps.LatLng(lat, long));
                        }
                        marker = new google.maps.Marker({
                            position: new google.maps.LatLng(lat, long),
                            map: $scope.map,
                            title: prestador.nombre,
                            icon: $scope.image
                        })
                        $scope.markers.push(marker);
                        $scope.listaInstituciones.push(prestador.instituciones[inst]);
                    };


                    latlngbounds = new google.maps.LatLngBounds();

                    $scope.LatLngList.forEach(function (latLng) {
                        latlngbounds.extend(latLng);
                    });

                    $scope.map.setCenter(latlngbounds.getCenter());
                    if ($scope.listaPrestadores.length > 1) {
                        $scope.map.fitBounds(latlngbounds);
                    } else {
                        $scope.map.setZoom(16);
                    }

                }

                var escapeRegExp = function (str) {
                    return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
                }

                var sinAcentos = function (s) {
                    var r = s.toLowerCase();
                    r = r.replace(new RegExp("[àáâãäå]", 'g'), "a");
                    r = r.replace(new RegExp("[èéêë]", 'g'), "e");
                    r = r.replace(new RegExp("[ìíîï]", 'g'), "i");
                    r = r.replace(new RegExp("ñ", 'g'), "n");
                    r = r.replace(new RegExp("[òóôõö]", 'g'), "o");
                    r = r.replace(new RegExp("[ùúûü]", 'g'), "u");
                    r = r.replace(new RegExp("[ýÿ]", 'g'), "y");
                    return r;
                };

                var redirectPrestador = function (path) {
                    $location.path(path);
                    $scope.$apply();
                }

                function removeMarkers() {
                    for (i = 0; i < $scope.markers.length; i++) {
                        $scope.markers[i].setMap(null);
                    }
                    $scope.markers.length = 0;
                }

                var replaceAll = function (str, find, replace) {
                    return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
                }

                //prestador onclick event
                $scope.setPrestadorLocation = function (prestador) {

                    var image = 'resources/images/marker.png';
                    var lat, long;
                    $scope.LatLngList = [];
                    removeMarkers();

                    for (i = 0; i < prestador.instituciones.length; i++) {

                        console.log("aca",prestador);
                        var telefonos;
                        telefonos = prestador.instituciones[i].telefonos[0] ? prestador.instituciones[i].telefonos[0] : '';
                        telefonos = prestador.instituciones[i].telefonos[1] ? telefonos + ' / ' + prestador.instituciones[i].telefonos[1] : telefonos;

                        var locationPath = '/medicos/' + sinAcentos(replaceAll(prestador.nombre, ' ', '-')) + '-' + prestador.alias;
                        var content = document.createElement('div'),
                            button;
                        content.innerHTML = '<div class="iw-container">' +
                            '<div class="iw-title">' + prestador.nombre + '</div>' +
                            // '<div class="iw-tel">' + prestador.telefono + '</div>' +
                            '<div class="iw-tel">' + telefonos + '</div>' +
                            '<div class="iw-location">' + prestador.instituciones[i].razonSocial + '</div>' +
                            '<div class="iw-location">' + prestador.instituciones[i].direccion.domicilio + ' ' + prestador.instituciones[i].direccion.altura + '</div>' +
                            '</div>';
                        button = content.appendChild(document.createElement('input'));
                        button.type = 'button';
                        button.value = 'SACAR TURNO';
                        button.className = 'btn';
                        // button.innerHTML = '<div><input class="btn" tyle="button" value="SACAR TURNO"/></div>';
                        google.maps.event.addDomListener(button, 'click', function () {
                            redirectPrestador(locationPath)
                            // $location.path($location.path() + '/' + path);
                        })
                        // Create infoWindow
                        var infoWindow = new google.maps.InfoWindow({
                            content: content
                        });

                        lat = prestador.instituciones[i].direccion.coord[0];
                        long = prestador.instituciones[i].direccion.coord[1];

                        if (lat && long) {
                            $scope.LatLngList.push(new google.maps.LatLng(lat, long));
                        }

                        var marker = new google.maps.Marker({
                            position: new google.maps.LatLng(lat, long),
                            map: $scope.map,
                            icon: image,
                            animation: google.maps.Animation.DROP,
                            infoWindow: infoWindow
                        });
                        $scope.markers.push(marker);
                        infoWindow.open($scope.map, marker);
                    }

                    latlngbounds = new google.maps.LatLngBounds();
                    $scope.LatLngList.forEach(function (latLng) {
                        latlngbounds.extend(latLng);
                    });


                    $scope.map.setCenter(latlngbounds.getCenter());

                    if ($scope.markers.length == 1) {
                        $scope.map.setZoom(16);
                    } else {
                        $scope.map.fitBounds(latlngbounds);
                    }

                }

                var ordenarLista = function (lista) {
                    lista.sort(function (a, b) {
                        if (a < b) return -1;
                        if (a > b) return 1;
                        return 0;
                    })
                } 

                var ordenarListaUbicacion = function (lista) {
                    lista.sort(function (a, b) {
                        if (a.ciudad < b.ciudad) return -1;
                        if (a.ciudad > b.ciudad) return 1;
                        return 0;
                    })
                }


                var llenarResultados = function (j, institucion) {
                    var posPrestador = 0;
                    var nuevoRegistro = $scope.nuevoResultado();

                    institucion.prestadores = filtrarPrestadores(institucion);

                    posPrestador = j;
                    nuevoRegistro.email = institucion.prestadores[posPrestador].perfil.email;
                    posicionRegistro = $scope.obtenerRegistroByEmail(institucion.prestadores[posPrestador].perfil.email);

                    //Si el prestador ya existe en el array, sólo le agrego la institución
                    if (posicionRegistro) {
                        var nuevaInstitucion = $scope.nuevaInstitucion();

                        nuevaInstitucion.razonSocial = institucion.razonSocial;
                        nuevaInstitucion.idInstitucion = institucion._id;
                        nuevaInstitucion.direccion = institucion.direccion;
                        nuevaInstitucion.cobertura = institucion.cobertura;
                        nuevaInstitucion.telefonos = institucion.telefonos;

                        for (i = 0; i < institucion.cobertura.length; i++) {
                            if ($scope.filterPrepagas.indexOf(institucion.cobertura[i].prepaga) == -1) {
                                $scope.filterPrepagas.push(institucion.cobertura[i].prepaga);
                                ordenarLista($scope.filterPrepagas);
                            }
                        }

                        var insertar = true;

                        for (i = 0; i < $scope.filterUbicacion.length; i++) {
                            if ($scope.filterUbicacion[i].ciudad == institucion.direccion.ciudad &&
                                $scope.filterUbicacion[i].provincia == institucion.direccion.provincia) {
                                insertar = false;
                            }
                        }

                        if (insertar) {
                            $scope.filterUbicacion.push({ "ciudad": institucion.direccion.ciudad, "provincia": institucion.direccion.provincia });
                            ordenarLista($scope.filterUbicacion);
                        }

                        $scope.listaPrestadores[posicionRegistro].instituciones.push(nuevaInstitucion);
                    }
                    else {
                        nuevoRegistro.prestador_id = institucion.prestadores[posPrestador].prestador_id;
                        nuevoRegistro.nombre = institucion.prestadores[posPrestador].perfil.nombre;
                        nuevoRegistro.telefono = institucion.prestadores[posPrestador].perfil.telefono;
                        nuevoRegistro.picture = institucion.prestadores[posPrestador].perfil.picture;
                        nuevoRegistro.alias = institucion.prestadores[posPrestador].alias;

                        var nuevaInstitucion = $scope.nuevaInstitucion();

                        nuevaInstitucion.razonSocial = institucion.razonSocial;
                        nuevaInstitucion.idInstitucion = institucion._id;
                        nuevaInstitucion.direccion = institucion.direccion;
                        nuevaInstitucion.cobertura = institucion.cobertura;
                        nuevaInstitucion.telefonos = institucion.telefonos;

                        for (i = 0; i < institucion.cobertura.length; i++) {
                            if ($scope.filterPrepagas.indexOf(institucion.cobertura[i].prepaga) == -1) {
                                $scope.filterPrepagas.push(institucion.cobertura[i].prepaga);
                                ordenarLista($scope.filterPrepagas);
                            }
                        }
                        var insertar = true;

                        for (i = 0; i < $scope.filterUbicacion.length; i++) {
                            if ($scope.filterUbicacion[i].ciudad == institucion.direccion.ciudad &&
                                $scope.filterUbicacion[i].provincia == institucion.direccion.provincia) {
                                insertar = false;
                            }
                        }

                        if (insertar) {
                            $scope.filterUbicacion.push({ "ciudad": institucion.direccion.ciudad, "provincia": institucion.direccion.provincia });
                            ordenarListaUbicacion($scope.filterUbicacion);
                        }

                        nuevoRegistro.instituciones.push(nuevaInstitucion);

                        for (i = 0; i < institucion.prestadores[posPrestador].perfil.profesiones.length; i++) {
                            var nuevaProfesion = $scope.nuevaProfesion();

                            for (m = 0; m < institucion.prestadores[posPrestador].perfil.profesiones[i].especialidades.length; m++) {
                                var nuevaEspecialidad = $scope.nuevaEspecialidad();
                                nuevaEspecialidad.nombre = institucion.prestadores[posPrestador].perfil.profesiones[i].especialidades[m].nombre;
                                nuevaProfesion.especialidades.push(nuevaEspecialidad);
                            }
                            nuevaProfesion.nombre = institucion.prestadores[posPrestador].perfil.profesiones[i].nombre;
                            nuevoRegistro.profesiones.push(nuevaProfesion);

                            if ($scope.filterProfesiones.indexOf(nuevaProfesion.nombre) == -1) {
                                $scope.filterProfesiones.push(nuevaProfesion.nombre);
                                ordenarLista($scope.filterProfesiones);
                            }

                        }

                        $scope.listaPrestadores.push(nuevoRegistro);
                        $scope.actualizarMapa(nuevoRegistro);

                    }
                    $scope.listaPrestadoresToFilter = $scope.listaPrestadores;
                    google.maps.event.trigger($scope.map, "resize");
                }

                var filtrarPrestadores = function (institucion) {
                    var prestadoresAFiltrar = new Array();
                    for (i = 0; i < institucion.prestadores.length; i++) {
                    if (institucion.prestadores[i].seguridad.idRol == "56a6ca4b2189b35862b6ee8f" || institucion.prestadores[i].seguridad.idRol == "57098924f20f37ffc0632131") {
                            prestadoresAFiltrar.push(institucion.prestadores[i]);
                        }
                    }
                    return prestadoresAFiltrar;
                }

                var obtenerInstitucion = function (resultados, k) {
                    srvcPrestadores.getInstitucionLiviana(resultados[k]._id)
                        .then(function (institucion) {

                            for (n = 0; n < institucion.prestadores.length; n++) {
                                llenarResultados(n, institucion);
                            }

                            if (k == (resultados.length - 1)) {
                                $scope.activeScroll = true;
                            }

                        })
                }

                $scope.loadMore = function () {
                    if ($scope.buscaInicio == 0) {
                        $scope.buscaInicio = $scope.buscaFin;
                    }
                    else {
                        $scope.buscaInicio = $scope.buscaFin + 1;
                    }

                    $scope.buscaFin = $scope.buscaFin + 24;

                    $scope.formatearResultados($scope.buscaInicio, $scope.buscaFin);
                };

                $scope.formatearResultados = function (inicio, fin) {
                    $scope.activeScroll = false;
                    srvcBusqueda.searchFor($scope.query, $scope.lugar, $scope.buscaInicio, $scope.buscaFin)
                        .then(function (res) {
                            $scope.resultados = res;
                            // console.log("REsultado de bsuqueda: ", res);

                            if (res) {
                                for (k = 0; k < res.length; k++) {
                                    obtenerInstitucion(res, k);
                                }
                            }

                        });

                }

                $scope.limpiarPrestadores = function (prestadores) {

                    prestadores = [];
                    return prestadores;
                }

                $scope.nuevoResultado = function () {
                    return { prestador_id: 0, nombre: '', instituciones: [], profesiones: [], telefono: 0, picture: '' };
                }

                $scope.nuevaInstitucion = function () {
                    return { razonSocial: "", institucion_id: "", direccion: { domicilio: "", provincia: "", ciudad: "", cp: 0, altura: 0, piso: "", departamento: "", coord: [0, 0] } }
                }

                $scope.nuevaProfesion = function () {
                    return { nombre: "", especialidades: [{ nombre: "" }] };
                }

                $scope.nuevaEspecialidad = function () {
                    return { nombre: "" };
                }

                $scope.obtenerRegistroByEmail = function (email) {
                    var posicion = null;
                    for (var i = 0; i < $scope.listaPrestadores.length; i++) {
                        if ($scope.listaPrestadores[i].email == email) {
                            posicion = i;
                        }
                    }

                    return posicion;
                }

                $scope.obtenerPrestadorByCodigo = function (prestadores, codigo) {
                    for (var i = 0; i < prestadores.length; i++) {
                        if (prestadores[i].prestador_id == codigo) {
                            return i;
                        }
                    }
                }


                //filter logica
                $scope.changeAndFilter = function () {

                    var ubicacion = $scope.selectedUbicacion ? $scope.selectedUbicacion.split("|") : null;

                    $scope.listaPrestadoresToFilter = $scope.listaPrestadores.filter(function (item) {

                        var especialidadOK = item.profesiones[0].nombre == $scope.selectedEspecialidad;

                        for (i = 0; i < item.instituciones.length; i++) {
                            var ubicacionOK = true;
                            if (ubicacion)
                                ubicacionOK = (item.instituciones[i].direccion.ciudad == ubicacion[0] && item.instituciones[i].direccion.provincia == ubicacion[1]);

                            if (item.instituciones[i].cobertura.length > 0) {
                                var prepagaOK = false;

                                for (x = 0; x < item.instituciones[i].cobertura.length; x++) {

                                    if (item.instituciones[i].cobertura[x].prepaga == $scope.selectedPrepaga) {
                                        var prepagaOK = true;
                                    }

                                }
                            }
                        }

                        return ((prepagaOK || $scope.selectedPrepaga == "TODAS" || $scope.selectedPrepaga == "Obra Social")
                            && (especialidadOK || $scope.selectedEspecialidad == "TODAS" || $scope.selectedEspecialidad == "Especialidad")
                            && (ubicacionOK || $scope.selectedUbicacion == "TODAS" || $scope.selectedUbicacion == "Ubicación")
                        );

                    })
                    $scope.LatLngList = [];
                    removeMarkers();
                    $scope.init();
                    for (a = 0; a < $scope.listaPrestadoresToFilter.length; a++) {
                        $scope.actualizarMapa($scope.listaPrestadoresToFilter[a]);
                    }
                }

                //Entrada

                $(window).scroll(function () {

                    var a = 140;
                    var pos = $(window).scrollTop();
                    var altura = $('#results').height();

                    if (pos > a + 100) {
                        $('#mapita').css({
                            position: 'fixed',
                            top: '8%',
                            marginTop: '20px',
                            width: '23.7%'

                        });
                        if (pos > altura - 320) {
                            $('#mapita').css({
                                marginTop: '-178px'
                            });
                        }
                    }
                    else {
                        $('#mapita').css({
                            position: 'absolute',
                            width: '100%',
                            marginTop: '20px'
                        });
                    }
                });


                $scope.markers = [];
                $scope.LatLngList = [];
                $scope.filterPrepagas = [];
                $scope.filterUbicacion = [];
                $scope.filterProfesiones = [];
                $scope.listaPrestadores = [];
                $scope.listaPrestadoresToFilter = [];
                $scope.selectedEspecialidad = "Especialidad";
                $scope.selectedPrepaga = "Obra Social";
                $scope.selectedUbicacion = "Ubicación";
                $scope.listaInstituciones = [];
                $scope.buscaInicio = 0;
                $scope.buscaFin = 0;
                $scope.activeScroll = true;
                $scope.cantidadResultados = 0;

                $scope.query = $routeParams.query;
                $scope.lugar = $routeParams.lugar;
                $scope.init();

                srvcBusqueda.searchForCount($scope.query, $scope.lugar)
                    .then(function (res) {
                        $scope.cantidadResultados = res;
                    });

            }]);

}());

