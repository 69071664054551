(function() {
    'use strict';

    angular.module('TodoTurnosApp')
        .controller('HomeController',
        ['$scope', '$routeParams', '$location', 'srvcSeguridad', function ($scope, $routeParams, $location, srvcSeguridad) {
            
        
        $scope.items = [
            {name: 'item1', pregunta: '¿Preciso tener Página Web para dar turnos por Internet?', respuesta:'No. TodoTurnos permite la asignación de turnos directamente desde el sitio. Cuando usted se registre, el sistema le otorgará la dirección web: www.TodoTurnos.com/SuNombre. Por otra parte, si usted tiene página web puede dar turnos directamente desde su página. Por último, el sistema permite que sus clientes lo busquen en el potente buscador que tiene nuestra herramienta.'},
            {name: 'item2', pregunta: '¿Es necesario tener una computadora con Internet en el establecimiento?', respuesta:'No. Si usted no está conectado a internet en algun momento, el sistema sigue funcionando pero le recomendamos que tenga a la mano una PC con Internet o Celular para que reciba diariamente la agenda de trabajo.'},
            {name: 'item3', pregunta: '¿La información que utiliza el sistema es pública?', respuesta:'Toda información personal cargada en la plataforma por los usuarios es confidencial. No es de acceso público. Al solicitar un turno, solo se comparte la información básica con el prestador del servicio en cuestión. Para más información lea nuestra política de confidencialidad.'},
            {name: 'item4', pregunta: '¿Cómo debo hacer para brindar turnos online?', respuesta:'Hay dos formas. Puede CONTACTARNOS y nosotros nos comunicaremos con usted. O bien puede seleccionar EL PLAN que mas se ajuste a su necesidad.'},
            {name: 'item5', pregunta: '¿Mi paciente puede re-agendar o cancelar su turno en cualquier momento?', respuesta:'Sí. Con una cantidad de horas previas al turno que usted decida el sistema permite re-agendar turnos o cancelarlos tan solo con una computadora o internet. Usted será informado de cada movimiento.'},
            {name: 'item6', pregunta: '¿Puedo dar turnos cuando me voy de vacaciones?', respuesta:'Si. Usted definirá un plazo de días como no laborables y el sistema bloquera la reserva en ese período pero ofrecerá turnos para dias posteriores a su regreso para que tenga su agenda preparada para seguir trabajando.'}
        ];

        $scope.open = function(item){
            if ($scope.isOpen(item)){
                $scope.opened = undefined;
            } else {
                $scope.opened = item;
            }        
        };
    
        $scope.isOpen = function(item){
            return $scope.opened === item;
        };

        $scope.opt = {
            nombre: 'agenda',
            texto: 'Agenda por cada profesional, aviso de Reserva y Recordatorio.'
        }
        
        $scope.openSection = function(op){
           
            switch(op){

                case 'agendas':
                        $scope.opt =  {nombre: 'agenda', texto: 'Agenda por cada profesional, aviso de Reserva y Recordatorio.'}
                break;

                case 'historia':
                        $scope.opt =  {nombre: 'historia', texto: 'Historia Clínica Digital, archivos adjuntos y mucho más.'}
                break;

                case 'turnos':
                        $scope.opt =  {nombre: 'turnos', texto: 'Página web exclusiva para que sus pacientes obtengan turnos por internet.'}
                break;

                case 'control':
                        $scope.opt =  {nombre: 'control', texto: 'Lleve el control de los ingresos y egresos.'}
                break;
                case 'appmovil':
                        $scope.opt =  {nombre: 'appmovil', texto: 'Puede solicitar una app exclusiva para su Centro.'}
                break;

                default: break;     

            }
         console.log("opt:",  $scope.opt);
        }

        

        $(document).ready(function() {
            if($routeParams.origen == 'mail'){
                if($(window).width() < 768){
                    $("html, body").animate({ scrollTop: $('#Contactenos').offset().top +  1200}, 1000);
                }else{
                    $("html, body").animate({ scrollTop: $('#Contactenos').offset().top + 600 }, 1000);
                }
                 
            }
        });
    }]);
})();