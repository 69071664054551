(function () {
    if (!Date.prototype.toLocalISOString) {
        (function () {

            function pad(number) {
                if (number < 10) {
                    return '0' + number;
                }
                return number;
            }

            Date.prototype.toLocalISOString = function () {
                return this.getFullYear() +
                    '-' + pad(this.getMonth() + 1) +
                    '-' + pad(this.getDate()) +
                    'T' + pad(this.getHours()) +
                    ':' + pad(this.getMinutes()) +
                    ':' + pad(this.getSeconds()) +
                    '.' + (this.getMilliseconds() / 1000).toFixed(3).slice(2, 5) +
                    'Z';
            };

            Date.prototype.addDays = function (days) {
                return new Date(this.getTime() + days * 24 * 3600000);
            };

        } ());
    }

    angular.module('TodoTurnosApp')
        .service('TodoTurnosAppUtils', [function () {
            var o = {
                getComienzoSemanaActual: function () {
                    var hoy = new Date();
                    return hoy.setDate(hoy.getDate() - hoy.getDay() + 1);
                },
                getFinSemanaActual: function () {
                    var hoy = new Date();
                    return hoy.setDate(hoy.getDate() - hoy.getDay() + 7);
                },
                getComienzoMesActual: function () {
                    var hoy = new Date();
                    hoy.setDate(1);
                    return hoy;
                },
                getFinMesActual: function () {
                    var hoy = new Date();
                    hoy.setMonth(hoy.getMonth() + 1);
                    return hoy - hoy.getDate() * 24 * 3600 * 1000;
                },
                getComienzoMes: function (mes, anio) {
                    var hoy = new Date(anio, mes);
                    hoy.setDate(1);
                    return hoy;
                },
                getFinMes: function (mes, anio) {
                    var hoy = new Date(anio, mes);
                    hoy.setMonth(hoy.getMonth() + 1);
                    return hoy - hoy.getDate() * 24 * 3600 * 1000;
                },
                getComienzoDia: function (date) {
                    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
                },
                getFinDia: function (date) {
                    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
                },
                clone: function (o) {
                    return JSON.parse(JSON.stringify(o))
                },
                getTimeofDayInMinutes: function (date) {
                    return date.getHours() * 60 + date.getMinutes();
                },
                audit: function (obj) {
                    if(obj.auditoria){
                        obj.auditoria.fechaHoraUltimaActualizacion = new Date().toLocalISOString();
                        obj.auditoria.usuarioUltimaActualizacion_id = "MODIFICADO POR PACIENTE";
                    }else{
                        obj.auditoria = {
                            fechaHoraCreacion: new Date().toLocalISOString(),
                            usuarioCreacion_id: "CREADO POR PACIENTE",
                            fechaHoraUltimaActualizacion: new Date().toLocalISOString(),
                            usuarioUltimaActualizacion_id: "MODIFICADO POR PACIENTE"
                        };
                    }
                },
                keysToArray: function (dicc) {
                    var arr = [];
                    angular.forEach(dicc, function (value, key) {
                        arr.push({ _id: key, codigo: value.codigo, descripcion: value.descripcion });
                    });
                    return arr;
                },
                buildPathFromRoute: function (routeObj) {
                    var path = routeObj.$$route.originalPath;
                    for (var property in routeObj.pathParams) {
                        if (routeObj.pathParams.hasOwnProperty(property)) {
                            var regEx = new RegExp(":" + property, "gi");
                            path = path.replace(regEx, routeObj.pathParams[property].toString());
                        }
                    }
                    return path;
                },
                getUrlParameter: function (param, dummyPath) {
                    
                    var sPageURL = dummyPath || window.location.search.substring(1),
                        sURLVariables = sPageURL.split(/[&||?]/),
                        res;

                    for (var i = 0; i < sURLVariables.length; i += 1) {
                        var paramName = sURLVariables[i],
                            sParameterName = (paramName || '').split('=');

                        if (sParameterName[0] === param) {
                            res = sParameterName[1];
                        }
                    }

                    return res;
                }
            };
            return o;
        }]);

} ());