(function () {
    angular.module('TodoTurnosApp')
        .service('srvcSeguridad', ['authService', '$q', 'srvcPacientes', '$rootScope', 'TodoTurnosAppSession', '$location', '$timeout', '$http',
            function (authService, $q, srvcPacientes, $rootScope, session, $location, $timeout, $http) {
                $rootScope.$on('authenticated', function (event, data) {
                    _setPerfilUsuario(data.profile, data.authResult).then(
                        function () {
                            $rootScope.$broadcast('profileLoaded', {});
                            _redirect();
                        }, function (err) {
                            if (err.notexists) {
                                _redirect({ hasProfile: false });
                            }
                        }
                    );

                });
                var _redirect = function (options) {
                    if (options && options.hasProfile == false) {
                        $location.path('/registroPaciente');
                    }
                    else {
                        var route = session.getUltimaRuta();
                        if (route && route != 'null') {
                            $location.path(route);
                            session.setUltimaRuta(null);
                        }
                        else {
                            $location.path('/');
                        }
                    }
                };
                var _storeUsuarioAutenticado = function (profile, authResult) {
                    session.setProfile(profile);
                    if (authResult) {
                        session.setToken(authResult.idToken);
                        session.setAccessToken(authResult.accessToken);
                    }
                };
                var _getUsuarioAutenticado = function () {
                    if (authService.isAuthenticated()) {
                        var _profile, _token;
                        _profile = session.getProfile();
                        _token = session.getToken();
                        _accessToken = session.getAccessToken();
                        return { profile: _profile, token: _token, accessToken: _accessToken };
                    }
                    return null;
                };
                var _setPerfilUsuario = function (profile, authResult) {
                    var defer = $q.defer();
                    _linkPaciente(profile).then(function (data) {
                        profile.metadata = data;
                        _storeUsuarioAutenticado(profile, authResult);
                        defer.resolve();
                    }, function (err) {
                        _storeUsuarioAutenticado(profile, authResult);
                        defer.reject(err);
                    });
                    return defer.promise;
                };
                var _linkPaciente = function (profile) {
                    var defer = $q.defer();
                    if (profile) {
                        srvcPacientes.getPaciente(profile.email.toUpperCase()).then(function (data) {
                            if (data) {
                                if (data.estado == 'P') {
                                    //data.estado = 'E';
                                    srvcPacientes.updateEstadoPaciente(data).then(function () {
                                        defer.resolve(data);
                                    }, function (err) {
                                        defer.reject(err);
                                    });
                                }
                                else {
                                    defer.resolve(data);
                                }
                            }
                            else {
                                defer.reject({ notexists: true });
                            }
                        }, function (err) {
                            defer.reject(err);
                        });
                    }
                    return defer.promise;
                }

                //API pública
                var o = {
                    setPerfilUsuario: _setPerfilUsuario
                    , getPerfilUsuario: function () {
                        return _getUsuarioAutenticado();
                    },
                    linkPerfilUsuario: function (paciente) {
                        var prfl = _getUsuarioAutenticado();
                        if (prfl && prfl.profile) {
                            prfl.profile.metadata = paciente;
                            _storeUsuarioAutenticado(prfl.profile);
                        }
                    }
                };
                return o;
            }]);
} ());