(function () {
    angular.module('TodoTurnosApp')
        .service('srvcPacientes', ['$http', '$q', 'TodoTurnosAppConfig',
            function ($http, $q, config) {
                var _getPaciente = function (email) {
                    var defer = $q.defer();
                    $http({ method: 'GET', url: config.urlRestServices + 'pacientes/email/' + email })
                        .then(function (response) {
                            defer.resolve(response.data);
                        }, function (err) {
                            defer.reject(err);
                        });
                    return defer.promise;
                };
                var _getPacienteById = function (id) {
                    var defer = $q.defer();
                    $http({ method: 'GET', url: config.urlRestServices + 'pacientes/' + id })
                        .then(function (response) {
                            defer.resolve(response.data);
                        }, function (err) {
                            defer.reject(err);
                        });
                    return defer.promise;
                };
                var _updateEstadoPaciente = function (paciente) {
                    var defer = $q.defer();
                    var fnOk = function (response) {
                        defer.resolve({ data: response.data, refresh: true });
                    };
                    $http({ method: 'POST', url: config.urlRestServices + 'pacientes/updateEstado', data: paciente })
                        .then(fnOk, function (err) {
                            defer.reject(err);
                        });
                    return defer.promise;
                };


                //API pública
                var o = {
                    getPaciente: function (email) {
                        return _getPaciente(email);
                    },
                    getPacienteById: function (id) {
                        return _getPacienteById(id);
                    },
                    getFamiliaByEmail: function (email) {
                        return _getPaciente(email);
                    },
                    updateEstadoPaciente: function (paciente) {
                        return _updateEstadoPaciente(paciente);
                    },
                    insert: function (paciente) {
                        var defer = $q.defer();
                        $http({ method: 'PUT', url: config.urlRestServices + 'pacientes', data: paciente })
                            .then(function (response) {
                                defer.resolve(response.data);
                            }, function (err) {
                                defer.reject(err);
                            });
                        return defer.promise;
                    },
                    upsert: function (paciente) {
                        var defer = $q.defer();
                        $http({ method: 'PUT', url: config.urlRestServices + 'pacientes/upsert', data: paciente })
                            .then(function (response) {
                                defer.resolve(response.data);
                            }, function (err) {
                                defer.reject(err);
                            });
                        return defer.promise;
                    },
                    update: function (paciente) {
                        var defer = $q.defer();
                        $http({ method: 'POST', url: config.urlRestServices + 'pacientes', data: paciente })
                            .then(function (response) {
                                defer.resolve(response.data);
                            }, function (err) {
                                defer.reject(err);
                            });
                        return defer.promise;
                    },

                    getTurnosByPaciente: function (idPaciente) {
                        var defer = $q.defer();
                        $http({ method: 'GET', url: config.urlRestServices + 'pacientes/' + idPaciente + '/Turnos' })
                            .then(function (response) {
                                defer.resolve(response.data);
                            }, function (err) {
                                defer.reject(err);
                            });
                        return defer.promise;
                    },

                    upsertTurno: function (turno) {
                        var durFix = false;
                        var defer = $q.defer();
                        var fnOk = function (response) {
                            defer.resolve({ data: response.data, refresh: durFix });
                        };
                        $http({ method: 'PUT', url: config.urlRestServices + 'turnos/', data: turno })
                            .then(fnOk, function (err) {
                                defer.reject(err);
                            });
                        return defer.promise;
                    },

                    getTurnoById: function (id) {
                        var defer = $q.defer();
                        $http({ method: 'GET', url: config.urlRestServices + 'turnos/' + id })
                            .then(function (response) {
                                defer.resolve(response.data);
                            }, function (err) {
                                defer.reject(err);
                            });
                        return defer.promise;
                    },

                    getFamiliaByEmail: function (email) {
                        var defer = $q.defer();
                        $http({ method: 'GET', url: config.urlRestServices + 'pacientes/' + 'familialookup/' + email })
                            .then(function (response) {
                                defer.resolve(response.data);
                            }, function (err) {
                                defer.reject(err);
                            });
                        return defer.promise;
                    }

                };
                return o;
            }]);
} ());