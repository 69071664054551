(function () {
    angular.module('TodoTurnosApp').controller('portadaInstitucionController', [
        '$scope', 
        '$routeParams',
        '$compile', 
        'srvcPrestadores', 
        'TodoTurnosAppSession', 
        'TodoTurnosAppUtils',
        '$location',
        function ($scope, $routeParams, $compile, srvcPrestadores, session, utils, $location) {

            var isPrestador = function (item) {
                var rol = $scope.rolesDicc[item.seguridad.idRol];
                return rol.codigo.toLowerCase().indexOf('prestador') >= 0;
            };

            var getPrestadores = function (institucion) {
                var prestadores = [];
                for (var i = 0; i < institucion.prestadores.length; i++) {
                    if (isPrestador(institucion.prestadores[i]))
                    {
                        if(!institucion.prestadores[i].perfil.picture || institucion.prestadores[i].perfil.picture == ""){
                            institucion.prestadores[i].perfil.picture = '/resources/images/perfiles_h.jpg';
                        }
                        prestadores.push(institucion.prestadores[i]);
                        agregarProfesiones(institucion.prestadores[i].perfil);
                    }
                }
                return prestadores;
            };

            var agregarProfesiones = function(prestador)
            {
               for (var i = 0; i < prestador.profesiones.length; i++) {
                        if ($scope.profesiones.indexOf(prestador.profesiones[i].nombre)==-1) 
                        {
                            $scope.profesiones.push(prestador.profesiones[i].nombre);
                        }
                } 
            }

            $scope.porProfesion = function(profesion) {
                        return function(prestador) {
                            var tieneProfesion = false;
                             for (var i = 0; i < prestador.perfil.profesiones.length; i++) {
                                if (prestador.perfil.profesiones[i].nombre == profesion)
                                {
                                    tieneProfesion = true;
                                }
                            }
                         return tieneProfesion;
                        }
                    }

            var obtenerCoberturaInstitucion = function(institucion)
            {
                var cobertura = institucion.particular ? 'Particular' : '';

                for (var i = 0; i < institucion.cobertura.length; i++) {
                    cobertura = cobertura + ' , ' + institucion.cobertura[i].prepaga;
                }
                return cobertura;
            }
            
            var escapeRegExp = function(str) {
                return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
            }

            var sinAcentos = function(s){
                var r=s.toLowerCase();
                r = r.replace(new RegExp("[àáâãäå]", 'g'),"a");
                r = r.replace(new RegExp("[èéêë]", 'g'),"e");
                r = r.replace(new RegExp("[ìíîï]", 'g'),"i");
                r = r.replace(new RegExp("ñ", 'g'),"n");                            
                r = r.replace(new RegExp("[òóôõö]", 'g'),"o");
                r = r.replace(new RegExp("[ùúûü]", 'g'),"u");
                r = r.replace(new RegExp("[ýÿ]", 'g'),"y");
                return r;
            };

            var redirectPrestador = function(path){
                $location.path(path);
            }

            var replaceAll = function(str, find, caracter) {
                return str.replace(new RegExp(escapeRegExp(find), 'g'), caracter);
            }

            $scope.sacarTurno = function(prestador){
                
                var locationPath = '/medicos/' + sinAcentos(replaceAll(prestador.perfil.nombre,' ', '-')) +'-' + prestador.alias;
                redirectPrestador(locationPath);
            }

            $scope.agregarMapa = function () {
                $scope.mapProp = {
                    center: new google.maps.LatLng($scope.institucion.direccion.coord[0],$scope.institucion.direccion.coord[1]),
                    zoom: 15,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    mapTypeControlOptions: {
                        mapTypeIds: ['Styled']
                    },
                    disableDefaultUI: true,
                        // mapTypeId: 'Styled'
                };
            }

            $scope.mapInit = function () {
                var styles = [
                                {
                                    featureType: "all",
                                    stylers: [
                                    { saturation: -80 }
                                    ]
                                },{
                                    featureType: "road.arterial",
                                    elementType: "geometry",
                                    stylers: [
                                    { hue: "#00ffee" },
                                    { saturation: 50 }
                                    ]
                                },{
                                    featureType: "poi.business",
                                    elementType: "labels",
                                    stylers: [
                                    { visibility: "off" }
                                    ]
                                }
                            ];
            google.maps.event.addDomListener(window, 'load', $scope.agregarMapa());
            $scope.map = new google.maps.Map(document.getElementById("googleMap"), $scope.mapProp);
             var styledMapType = new google.maps.StyledMapType(styles, { name: 'Styled' });
            $scope.map.mapTypes.set('Styled', styledMapType);
            var image = 'resources/images/marker.png';
                        
            var marker = new google.maps.Marker({
                position: new google.maps.LatLng($scope.institucion.direccion.coord[0],$scope.institucion.direccion.coord[1]),
                map: $scope.map,
                title: $scope.institucion.razonSocial,
                icon: image
            });
            marker.setMap($scope.map);
        }

            //Punto de entrada
            $scope.profesiones = [];
            $scope.rolesDicc = session.getRoles();
            $scope.idInstitucion = $routeParams.idInstitucion;
            $scope.institucion   = {_id:'',cobertura:[],direccion:{},prestadores:[],razonSocial:'',telefonos:[]}

            srvcPrestadores.getInstitucion($scope.idInstitucion)
                .then(function (institucion) {
                    $scope.institucion = institucion;
                    if(!$scope.institucion.picture){
                        $scope.institucion.picture = "/resources/images/institucion.jpg";
                    }
                    $scope.institucion.prestadores = getPrestadores($scope.institucion);
                    $scope.cobertura = obtenerCoberturaInstitucion($scope.institucion);
                    $scope.piso = ($scope.institucion.direccion.piso || $scope.institucion.direccion.departamento) 
                                ? $scope.institucion.direccion.piso +  $scope.institucion.direccion.departamento : null
                   
                }).then(function(){ $scope.mapInit();});
        }]);
} ());