(function () {
    angular.module('TodoTurnosApp')
        .directive('turnosportables', function () {
            return {
                restrict: 'E',
                templateUrl: '../views/directives/turnosPortables.html',
                scope: {
                    idinstitucion: '=',
                    idprestador: '=',
                    start: '='
                },
                controller: function ($scope, $routeParams, srvcPrestadores, $location, srvcPacientes, TodoTurnosAppSession, SweetAlert, TodoTurnosAppUtils) {
                    $scope.ok = function () {
                        $uibModalInstance.close($ctrl.selected.item);
                    };
                    $scope.openTurno = function () {
                        $document.scrollTopAnimated(400).then(function () {
                        });

                        var someElement = angular.element(document.getElementById('some-id'));
                        $document.scrollToElementAnimated(someElement);
                    }

                    var getPrestador = function (institucion, idPrestador) {
                        for (var i = 0; i < institucion.prestadores.length; i++) {
                            if (institucion.prestadores[i].prestador_id == idPrestador)
                                return institucion.prestadores[i];
                        }
                        return null;
                    };

                    var getInstitucion = function () {
                        srvcPrestadores.getInstitucionLiviana($scope.idinstitucion).then(function (res) {
                            $scope.institucion = res;
                            console.log("permite turnos online", $scope.institucion.configuracion);
                            $scope.consul = res;


                            if ($scope.reservaResumen) {
                                $scope.prestador = getPrestador($scope.institucion, $scope.idprestador);
                            }

                            if ($scope.vinoPrestador) {
                                $scope.profesionalStep = false;
                                $scope.tratamientoStep = false;
                                $scope.especialidadStep = false;
                                $scope.calendarStep = false;
                                $scope.datosPacienteStep = false;
                                $scope.reservaStep = false;
                                $scope.toTratamientos($scope.idprestador);
                            }
                            $scope.getEspecialidades();
                        });
                    }

                    // $scope.open = function (item) {
                    //     var modalInstance = $uibModal.open({
                    //         animation: $scope.animationsEnabled,
                    //         ariaLabelledBy: 'modal-title',
                    //         ariaDescribedBy: 'modal-body',
                    //         templateUrl: './views/modals/showInfo.html',
                    //         controller: 'showInfoController',
                    //         size: 'md',
                    //         resolve: {
                    //             prestador: function () {
                    //                 return $scope.prestador;
                    //             },
                    //             tel: function () {
                    //                 return $scope.institucion.telefonos;
                    //             }
                    //         }
                    //     });
                    // }


                    $scope.agregarMapa = function () {
                        $scope.mapProp = {
                            // center: new google.maps.LatLng($scope.institucion.direccion.coord[0], $scope.institucion.direccion.coord[1]),
                            zoom: 15,
                            mapTypeId: google.maps.MapTypeId.ROADMAP,
                            mapTypeControlOptions: {
                                mapTypeIds: ['Styled']
                            },
                            disableDefaultUI: true,
                            // mapTypeId: 'Styled'
                        };
                    }
                    $scope.openConsultorio = function (id) {
                        $location.path('/consultorio/' + id);
                    }



                    $scope.setMarker = function (coords) {

                        var marker = new google.maps.Marker({
                            position: coords,
                            map: $scope.map,
                            title: 'Usted está aquí!',
                            icon: $scope.image
                        });
                        marker.setMap($scope.map);
                    }
                    $scope.$on('fechaTurnoSelected', function (evt, data) {
                        $scope.turnoFechaInicio = new Date(data);
                        $scope.calendarStep = false;
                        $scope.datosPacienteStep = true;
                        $scope.instruccionStep = "Ingrese sus datos personales";
                        $scope.back = true;
                    });


                    //funciones
                    $scope.toTratamientos = function (id) {
                        $scope.profesionalStep = false;
                        $scope.idprestador = id;
                        $scope.prestador = getPrestador($scope.institucion, $scope.idprestador);
                        $scope.especialidad = $scope.prestador.perfil.profesiones[0].nombre;
                        if ($scope.prestador.servicios.length > 0) {
                            $scope.tratamientoStep = true;
                            $scope.instruccionStep = "Seleccione un tratamiento";
                            $scope.serviciosFiltrados = [];
                            $scope.prestador.servicios.forEach(function (servicio) {
                                    $scope.serviciosFiltrados.push(servicio);
                            });

                        } else {
                            $scope.toCalendario(null);
                        }
                        $scope.back = !$scope.vinoPrestador;
                    }

                    $scope.toCalendario = function (servicio) {
                        $scope.refreshMini = 1;
                        $scope.tratamientoStep = false;
                        $scope.datosPacienteStep = false;
                        $scope.calendarStep = true;
                        if($scope.prestador.datosAgenda.bloquearTurnosOnline){
                            $scope.instruccionStep = "El profesional no se encuentra tomando turnos online";
                        } else {
                            $scope.instruccionStep = "Seleccione un mes, día y horario";
                        }

                        if (servicio != null) {
                            $scope.servicioSelected = servicio;
                        } else {
                            $scope.servicioSelected = { duracion: $scope.prestador.datosAgenda.duracionTurno }
                        }
                        $scope.back = !$scope.vinoPrestador || $scope.serviciosFiltrados.length > 0;
                        refreshCalendar();
                        console.log("to calendario")
                    }

                    $scope.toDatos = function () {

                        $scope.calendarStep = false;;
                        $scope.datosPacienteStep = true;
                        $scope.instruccionStep = "Ingrese sus datos personales";
                        $scope.back = true;

                    }

                    $scope.getEspecialidades = function () {

                        "prestador.seguridad.idRol!='56dcdb4c090b599e2ce05890' && prestador.seguridad.idRol!='56a6ca4b2189b35862b6ee8e'"
                        $scope.institucion.prestadores.forEach(function (prestador, index) {
                            if (prestador.seguridad.idRol != '56dcdb4c090b599e2ce05890' && prestador.seguridad.idRol != '56a6ca4b2189b35862b6ee8e') {
                                if ($scope.especialidades.indexOf(prestador.perfil.profesiones[0].nombre) == -1 && prestador.perfil.profesiones[0].nombre != undefined) {
                                    $scope.especialidades.push(prestador.perfil.profesiones[0].nombre);
                                }

                            }

                        });
                    }

                    $scope.toReserva = function () {
                        
                        
                        $scope.disableButton = true;
                        $scope.back = true;
                        var inst = [$scope.idinstitucion];
                        $scope.pacienteNuevo = {
                            tipoDocumento: '56a6ca4b2189b35862b6ee83',
                            sexo: '56a6ca4b2189b35862b6ee87',
                            nombre: $scope.pacienteNombre,
                            email: $scope.pacienteEmail,
                            numeroDocumento: $scope.pacienteDNI,
                            telefono:$scope.pacienteTelefono,
                            estado: 'P',
                            institucionOrigen: inst,
                        };

                        srvcPacientes.upsert($scope.pacienteNuevo).then(function (res) {
                            
                            $scope.pacienteCreado = res;

                            // if($scope.disponibilidadValida){
                            // persist(fixFechas(turno));
                            // $scope.turnoOk = true;
                            // }else{
                            //     toastr.error("No se Guardó el turno. Por favor, verifique la disponibilidad del medico nuevamente.", config.toastrMensajeConfig);
                            //}
                            var fechaFin = new Date($scope.turnoFechaInicio);

                            fechaFin = fechaFin.setMinutes(fechaFin.getMinutes() + $scope.servicioSelected.duracion);
                            var servicio = [];
                            servicio.push($scope.servicioSelected);
                            var turno = {
                                _id: '0',
                                idInstitucion: $scope.idinstitucion,
                                idPrestador: $scope.idprestador,
                                idPaciente: $scope.pacienteCreado._id,
                                descripcion: $scope.pacienteNombre,
                                paciente:{
                                    nombre: $scope.pacienteNombre,
                                    telefono:$scope.pacienteTelefono,
                                    dni:$scope.pacienteDNI
                                },
                                comentario: "",
                                fechaHoraInicio: new Date($scope.turnoFechaInicio).toLocalISOString(),
                                fechaHoraFin: new Date(fechaFin).toLocalISOString(),
                                esSobreturno: false,
                                concurrio: null,
                                adjuntos: null,
                                confirmoPaciente: true,
                                notificarPaciente: true,
                                estado: '56a6ca4b2189b35862b6ee89',//reservado por default
                                esInterno: false,
                                servicios: servicio
                            }

                            TodoTurnosAppUtils.audit(turno);


                            srvcPrestadores.getTurnosSolapados(turno.idInstitucion, turno.idPrestador, turno.fechaHoraInicio, turno.fechaHoraFin).then(function (res) {
                                if (res.length == 0) {
                                    upsert(turno);
                                } else {
                                    turnoNoDosiponibleAlert();
                                }
                                $scope.disableButton = false;
                            }, function (err) {
                                $scope.disableButton = false;
                                turnoNoDosiponibleAlert();
                            })

                        }, function (err) {
                            $scope.disableButton = false;
                            if (err.data && err.data.exists) {
                                turnoNoDosiponibleAlert()
                            }
                            else {
                                turnoNoDosiponibleAlert()
                            }
                            // $uibModalInstance.dismiss('cancel');
                        });
                    }

                    var upsert = function (turno) {

                        srvcPrestadores.upsertTurno(turno, $scope.servicioSelected.duracion)
                            .then(function (res) {
                                $scope.disableButton = false;
                                if (!$scope.esNuevoTurno) {

                                }
                                else {


                                }
                                $scope.datosPacienteStep = false;
                                $scope.reservaStep = true;
                                $scope.instruccionStep = "Detalle del turno solicitado:";
                                $scope.back = false;
                                //$location.path($location.path().split('turno')[0]);
                            }, function (err) {
                                $scope.disableButton = false;
                                turnoNoDosiponibleAlert();
                            });
                    }

                    var turnoNoDosiponibleAlert = function () {
                        SweetAlert.swal({
                            title: "Turno no disponible",
                            text: "El turno que solicitó ya no está disponible, por favor inténtelo nuevamente",
                            type: "error",
                            confirmButtonColor: "#DD6B55",
                            confirmButtonText: "Aceptar",
                            closeOnConfirm: true
                        },
                            function () {
                                location.reload(true);
                            });


                    }

                    var refreshCalendar = function () {
                        $scope.refresh = $scope.refresh == 1 ? 2 : 1;
                    }

                    $scope.toBack = function () {

                        $scope.refreshMini = 2;
                        
                        if ($scope.vinoPrestador && window.location.toString().indexOf("#/medicos/")>0 ) {
                            $scope.datosPacienteStep = false;
                            $scope.calendarStep = false;
                        
                            $scope.toTratamientos($scope.idprestador);
                            
                            return ;
                        }
                            
                         
                            

                        if ($scope.profesionalStep) {
                            $scope.toEspecialidad();
                            $scope.profesionalStep = false;
                        }
                        else {
                            if ($scope.tratamientoStep) {
                                $scope.toProfesionales($scope.especialidad);
                                $scope.tratamientoStep = false;
                            }
                            else {
                                if ($scope.calendarStep) {
                                    if ($scope.serviciosFiltrados.length > 0) {
                                        $scope.toTratamientos($scope.idprestador);
                                    } else {
                                        $scope.toProfesionales($scope.especialidad);
                                    }
                                    $scope.calendarStep = false;
                                }
                                else {
                                    if ($scope.datosPacienteStep) {
                                        if ($scope.vinoPrestador ) {
                                            
                                                $scope.toTratamientos($scope.idprestador);
                                                $scope.datosPacienteStep = false;
                                            
                                            
                                        } else {
                                            
                                                $scope.toEspecialidad();
                                            
                                            
                                        }
                                        $scope.datosPacienteStep = false;
                                       
                                    }
                                    else {
                                    }
                                }
                            }
                        }


                    
                        $scope.calendarStep = false;
                        $scope.datosPacienteStep = false;

                    }
                    $scope.dataChange = function () {
                    }


                    $scope.toEspecialidad = function () {
                        $scope.serviciosFiltrados = [];
                        $scope.profesionalStep = false;
                        $scope.especialidadStep = true;
                        $scope.instruccionStep = "Seleccione una especialidad:";
                        $scope.prestadoresFiltrados = [];
                        $scope.back = false;

                    }
                    $scope.toProfesionales = function (especialidad) {
                        $scope.prestador = null;
                        $scope.especialidad = especialidad;
                        $scope.prestadoresFiltrados = [];
                        $scope.institucion.prestadores.forEach(function (pres, index) {
                            if (pres.perfil.profesiones[0].nombre == especialidad) {
                                $scope.prestadoresFiltrados.push(pres);
                            }

                        });


                        $scope.especialidadStep = false;
                        $scope.profesionalStep = true;
                        $scope.instruccionStep = "Seleccione un profesional:";

                        $scope.back = true;

                    }
                    $scope.openCalendar = function () {
                        $('#calendario').animatescroll();
                    }
                    $scope.openComentarios = function () {
                        $('#comentarios').animatescroll();
                    }

                    var centerMap = function (center) {
                        google.maps.event.addListenerOnce($scope.map, "idle", function () {
                            google.maps.event.trigger($scope.map, "resize");
                            $scope.map.setCenter(center);
                        });
                    };

                    $scope.checkTimeZone = function () {
                        const fecha = new Date().getTimezoneOffset();
                        return fecha === 180 ? true : false;
                    };

                    //variables
                    $scope.vinoPrestador = $scope.idprestador != 'null';
                    $scope.especialidades = [];
                    $scope.especialidad = "";
                    $scope.prestadoresFiltrados = [];
                    $scope.serviciosFiltrados = [];
                    $scope.back = false;


                    $scope.consul = null;
                    $scope.reservaResumen = false;

                    if (($scope.reservaturno == 'null' || $scope.reservaturno == undefined || $scope.reservaturno == null)) {
                        $scope.especialidadStep = true;
                        $scope.instruccionStep = "Seleccione una especialidad";
                        $scope.reservaResumen = false;
                    }
                    else {
                        $scope.reservaResumen = true;
                        $scope.profesionalStep = false;
                        $scope.instruccionStep = "Resumen de su Turno";
                    }

                    $scope.reservaturno = new Date($scope.reservaturno);

                    $scope.profesionalStep = false;
                    $scope.tratamientoStep = false;
                    $scope.calendarStep = false;
                    $scope.datosPacienteStep = false;
                    $scope.reservaStep = false;
                    $scope.pacienteNombre = "";
                    $scope.pacienteEmail = "";
                    $scope.pacienteDNI = "";
                    $scope.pacienteTelefono = "";
                    $scope.turnoFechaInicio = new Date();


                    $scope.prestadorSelected = 0;
                    $scope.duracionSelected = 0;

                    $scope.calendar = true;


                    if ($scope.idinstitucion) {
                        getInstitucion();
                    }

                    if($scope.prestador){
                        console.log("tengo prestador", $scope.prestador)
                    }

                    $scope.$watch(function (scope) { return scope.idinstitucion },
                        function () {
                            if (!$scope.idinstitucion) {
                                return;
                            }
                            getInstitucion();
                        }
                    );
                    TodoTurnosAppSession.setParametrosSistema();
                    $scope.consultorios = [];
                    $scope.refresh = 1;
                    $scope.refreshMini = 1;
                }
            }
        })
}());