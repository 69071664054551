(function () {
    angular.module('TodoTurnosApp')
        .controller('geoCodeController',
        ['$scope'
            , '$routeParams'
            , 'srvcPrestadores'
            , 'TodoTurnosAppConfig'
            , 'mapsService'
            , function ($scope, $routeParams, srvcPrestadores, config, mapsService) {
                var end = function () {
                    $scope.geocoding = false;
                    search();
                };
                var search = function () {
                    $scope.limit = $routeParams.limit;
                    $scope.geocoding = false;
                    $scope.searched = false;
                    srvcPrestadores.getInstitucionesNotGeocoded($scope.limit).then(function (res) {
                        $scope.res = res.data;
                        $scope.searched = true;
                    });
                };
                $scope.go = function () {
                    $scope.log= '';
                    $scope.geocoding = true;
                    var j = 0;
                    for (var i = 0; i < $scope.res.length; i++) {
                        mapsService.geoCode($scope.res[i]).then(function (ins) {
                            j++;
                            $scope.log += "<br>" + ins._id + " geocodificada. " + "lat: " + ins.direccion.coord[0] + " long: " + ins.direccion.coord[1];
                            if (j == $scope.res.length - 1)
                                end();
                        }, function (err) {
                            j++;
                            $scope.log += "<br> error: " + err;
                            if (j == $scope.res.length - 1)
                                end();
                        }
                        );
                    }
                };
                search();
            }]);
} ());