(function () {
    angular.module('TodoTurnosApp')
        .controller('altaTurnoController',
        ['$scope'
            , 'srvcPacientes'
            , 'srvcSeguridad'
            , 'srvcPrestadores'
            , '$routeParams'
            , 'TodoTurnosAppConfig'
            , 'toastr'
            , 'store'
            , '$location'
            , '$uibModal'
            , 'TodoTurnosAppSession'
            , 'TodoTurnosAppUtils'
            // , '$uibModalInstance'
            , function ($scope, srvcPacientes, srvcSeguridad, srvcPrestadores, $routeParams, config, toastr, store, $location, $uibModal, session, utils) {

                var toArray = function (tipos) {
                    var arr = [];
                    angular.forEach(tipos, function (value, key) {
                        arr.push({ _id: key, codigo: value.codigo, descripcion: value.descripcion });
                    });
                    return arr;
                };

                $scope.validarDocumento = function () {
                    var dni = $scope.pacienteNuevo.numeroDocumento;
                    var regex = config.regExpressions.dni;
                    $scope.documentoValido = (dni && regex.test(dni));
                };
                $scope.validarNombre = function () {
                    var nombre = $scope.pacienteNuevo.nombre;
                    $scope.nombreValido = (nombre && nombre.length > 5);
                };

                $scope.isValid = function () {
                    return (($scope.turnoPara.valor == 'nuevoPaciente' && $scope.nombreValido && $scope.documentoValido && $scope.pacienteNuevo.tipoDocumento && $scope.pacienteNuevo.sexo)
                        || ($scope.turnoPara.valor == 'pacienteFrecuente' && $scope.pacienteFrecuente._id)
                        || ($scope.turnoPara.valor == 'pacienteSession'));
                }

                $scope.pacienteSession = function () {
                    $scope.pacientePrincipal = srvcSeguridad.getPerfilUsuario();
                    var estadoTurno = "";
                    angular.forEach(store.get('parametrosSistema').estadoTurno, function (val, key) {
                        if (val.codigo == "S") {
                            $scope.turnoSolicitado = val;
                            estadoTurno = { "id": key }
                        }
                    });

                    $scope.altaTurno = {
                        _id: '0',
                        idInstitucion: $scope.idInstitucion,
                        idPrestador: $scope.idPrestador,
                        idPaciente: $scope.pacientePrincipal.profile.metadata._id,
                        descripcion: $scope.pacientePrincipal.profile.metadata.nombre,
                        comentario: null,
                        estado: estadoTurno.id,
                        fechaHoraInicio: new Date($scope.horaInicio),
                        fechaHoraFin: new Date((Date.parse($scope.horaInicio) + $routeParams.duracion * 60 * 1000)),
                        esSobreturno: false,
                        esInterno: true,
                        confirmoPaciente: true
                    };
                };


                $scope.datosPrestador = function () {
                    srvcPrestadores.getPrestador($scope.idInstitucion, $scope.idPrestador).then(function (res) {
                        $scope.prestador = res[0].prestadores[0];
                    }, function (err) {
                        toastr.error("Ha ocurrido un error al obtener los datos del paciente. Por favor intente nuevamente más tarde.", config.toastrErrorConfig);
                    });
                };



                $scope.getFamilia = function () {
                    srvcPacientes.getFamiliaByEmail($scope.pacientePrincipal.profile.metadata.email)
                        .then(function (pacientes) {
                            var sortEmail = [];
                            for (var i = 0; i < pacientes.length; i++) {
                                var item = {};
                                item.email = pacientes[i].email;
                                item.numero = pacientes[i].email.indexOf('+') > -1 ? pacientes[i].email.substring(pacientes[i].email.lastIndexOf("+") + 1, pacientes[i].email.lastIndexOf("@")) : 0;
                                item.numero = parseInt(item.numero);
                                item.nombre = pacientes[i].nombre;
                                item._id = pacientes[i]._id;
                                sortEmail.push(item);
                            }

                            sortResults('numero', true);
                            //ordeno lista de pacientesFrecuentes por su +XX del mail
                            function sortResults(prop, asc) {
                                sortEmail = sortEmail.sort(function (a, b) {
                                    if (asc) {
                                        return (a[prop] > b[prop]) ? 1 : ((a[prop] < b[prop]) ? -1 : 0);
                                    } else {
                                        return (b[prop] > a[prop]) ? 1 : ((b[prop] < a[prop]) ? -1 : 0);
                                    }
                                })
                            }
                            $scope.pacientesFrecuentes = sortEmail;
                        });
                }

                $scope.setNewEmail = function (email) {
                    var textoemail = email.substring(0, email.indexOf("+")) == "" ? email.substring(0, email.indexOf("@")) : email.substring(0, email.indexOf("+"));
                    var dominio = email.substring(email.lastIndexOf("@") + 1);
                    var numeroRelacion = email.indexOf('+') > -1 ? email.substring(email.lastIndexOf("+") + 1, email.lastIndexOf("@")) : "+1";
                    var newNumber = parseInt(numeroRelacion) + 1;
                    //doy de alta nuevo mail de PacienteFrecuente a insertar
                    $scope.newEmail = textoemail + (email.indexOf('+') > -1 ? '+' + newNumber : numeroRelacion) + '@' + dominio;
                }

                $scope.setPacienteFrecuente = function (paciente) {
                    $scope.pacienteFrecuente = paciente;
                    $scope.turnoPara.valor = 'pacienteFrecuente';
                }


                var persist = function (turno) {
                    validateDisponibilidadPrestador(turno, function (dispo) {
                        if (dispo) {
                            srvcPrestadores.upsertTurno(turno, $scope.duracionTurno).then(function (res) {
                            }, function (err) {
                                toastr.error("Ha ocurrido un error al guardar el turno. Por favor intente nuevamente más tarde.", config.toastrErrorConfig);
                            });
                        }
                        else {
                            toastr.error("No hay dispo.", config.toastrErrorConfig);
                        }
                    }
                    )

                };

                var validateDisponibilidadPrestador = function (turno, callback) {
                    var evt = { start: turno.fechaHoraInicio.replace('Z', '-03:00'), end: turno.fechaHoraFin.replace('Z', '-03:00') };
                    srvcPrestadores.checkDisponibilidadPrestadorById($scope.idInstitucion, $scope.idPrestador, $scope.duracionTurno, evt).then(function (res) {
                        callback(res);
                    });
                }

                $scope.persistTurno = function (turno) {
                    var fixFechas = function (turno) {
                        var turnoDb = utils.clone(turno);
                        turnoDb.fechaHoraInicio = new Date($scope.altaTurno.fechaHoraInicio).toLocalISOString();
                        turnoDb.fechaHoraFin = new Date($scope.altaTurno.fechaHoraFin).toLocalISOString();
                        return turnoDb;
                    };
                    
                    if ($scope.turnoPara.valor == 'nuevoPaciente') {
                        //doy de alta el paciente
                        $scope.setNewEmail($scope.pacientesFrecuentes[$scope.pacientesFrecuentes.length - 1].email);
                        $scope.pacienteNuevo = {
                            tipoDocumento: $scope.pacienteNuevo.tipoDocumento,
                            sexo: $scope.pacienteNuevo.sexo,
                            nombre: $scope.pacienteNuevo.nombre,
                            estado: 'E',
                            email: $scope.newEmail,
                            numeroDocumento: $scope.pacienteNuevo.numeroDocumento,
                        };
                        srvcPacientes.insert($scope.pacienteNuevo).then(function (res) {
                            // $uibModalInstance.close(res);
                            turno.idPaciente = res._id;
                            turno.descripcion = $scope.pacienteNuevo.nombre;
                            // if($scope.disponibilidadValida){
                            persist(fixFechas(turno));
                            utils.audit(turno);
                            $scope.turnoOk = true;
                            // }else{
                            //     toastr.error("No se Guardó el turno. Por favor, verifique la disponibilidad del medico nuevamente.", config.toastrMensajeConfig);
                            //}

                        }, function (err) {
                            if (err.data.exists)
                                toastr.error("Ya existe un paciente con el mismo Documento.", config.toastrErrorConfig);
                            else
                                toastr.error("Ha ocurrido un error con su registración. Por favor intentelo nuevamente en unos instantes. ", config.toastrErrorConfig);
                            // $uibModalInstance.dismiss('cancel');
                        });
                        //Seteo el turno con el Email de este nuevo Paciente
                    } else if ($scope.turnoPara.valor == 'pacienteFrecuente') {
                        turno.idPaciente = $scope.pacienteFrecuente._id;
                        turno.descripcion = $scope.pacienteFrecuente.nombre;
                        //if($scope.disponibilidadValida){
                        utils.audit(turno);
                        persist(fixFechas(turno));
                        $scope.turnoOk = true;
                        //}else{
                        //toastr.error("No se Guardó el turno. Por favor, verifique la disponibilidad del medico nuevamente.", config.toastrMensajeConfig);
                        //}
                    }
                    else {
                        //if($scope.disponibilidadValida){
                        utils.audit(turno);
                        persist(fixFechas(turno));
                        $scope.turnoOk = true;
                        //}else{
                        //    toastr.error("No se Guardó el turno. Por favor, verifique la disponibilidad del medico nuevamente.", config.toastrMensajeConfig);
                        //}
                    }
                    if ($scope.turnoOk) {
                        var modalInstance = $uibModal.open({
                            animation: true,
                            templateUrl: './views/turnoOk.html',
                            controller: 'turnoOkController',
                            size: 'lg',
                            backdrop: 'static',
                            resolve: {
                                parTurno: function () {
                                    return turno;
                                },
                                parPrestador: function () {
                                    return $scope.prestador;
                                }
                            }
                        });
                        $location.path('/institucion/' + $scope.idInstitucion);
                    }

                };

                $scope.turnoParaTercero = function () {
                    if ($scope.turnoPara.valor == 'nuevoPaciente' || $scope.turnoPara.valor == 'pacienteFrecuente') {
                        if (!$scope.traigoPacientsFrecuentes) {
                            $scope.traigoPacientsFrecuentes = true;
                            $scope.getFamilia();
                        }
                        return true;
                    } else {
                        $scope.showFormAltaPaciente = false;
                        return false;
                    }
                }

                $scope.openNuevoPacienteForm = function () {
                    $scope.turnoPara.valor = 'nuevoPaciente';
                    //muestro formulario de altaPaciente
                    $scope.showFormAltaPaciente = true;
                    $scope.pacienteNuevo = {
                        tipoDocumento: null,
                        sexo: null,
                        nombre: null,
                        estado: 'E',
                        email: null,
                        numeroDocumento: null,
                    };
                }

                $scope.goback = function () {
                    $location.path('/institucion/' + $scope.idInstitucion);
                }

                //puntos de entrada
                $scope.tiposDocumento = toArray(session.getTiposDocumento());
                $scope.sexos = toArray(session.getSexos());
                $scope.idInstitucion = $routeParams.idInstitucion;
                $scope.idPrestador = $routeParams.idPrestador;
                $scope.horaInicio = $routeParams.start;
                $scope.duracionTurno = parseInt($routeParams.duracion);
                $scope.traigoPacientsFrecuentes = false;
                $scope.turnoPara = {
                    valor: 'pacienteSession',
                    email: null
                };
                $scope.pacienteSession();
                $scope.datosPrestador();
                $scope.showFormAltaPaciente = false;


            }]);

} ());