﻿(function () {
    angular.module('TodoTurnosApp')
        .directive('calendario', function () {
            return {
                restrict: 'E',
                templateUrl: '../views/directives/calendario.html',
                scope: {
                    prestador: '=',
                    institucion: '=',
                    fechaTurno: '='
                },
                controller: function ($scope, srvcPrestadores, toastr, $compile, uiCalendarConfig, $location, $rootScope,SweetAlert) {
                    var formaterarHoraArg = function(fecha) {
                        const timeZoneOffset = fecha.getTimezoneOffset() * 60000; 
                        const adjustedDate = new Date(fecha - timeZoneOffset);
                        const newDate = new Date(adjustedDate.getTime() - (3 * 60 * 60 * 1000));
                        const adjustedNewDate = new Date(newDate.getTime() + timeZoneOffset);
                        return adjustedNewDate;
                    } 
                    var renderTurno = function (turnoDb) {
                        var turnoUI = {
                            start: formaterarHoraArg(turnoDb.fechaHoraInicio),
                            end: formaterarHoraArg(turnoDb.fechaHoraFin),
                            className: 'eventoL'
                        };
                        return turnoUI;
                    };
                    $scope.calendarCtrl = function () {
                        $scope.eventSources = [];

                        var touches = null;
                        window.document.addEventListener('touchstart', function (e) {
                            touches = event.touches[0];
                        });

                        /* Change View */
                        $scope.renderCalendar = function (calendar) {
                            setTimeout(function () {
                                if (uiCalendarConfig.calendars[calendar]) {
                                    uiCalendarConfig.calendars[calendar].fullCalendar('render');
                                    uiCalendarConfig.calendars[calendar].fullCalendar('changeView', 'basicWeek');
                                    uiCalendarConfig.calendars[calendar].fullCalendar('gotoDate', new Date());
                                    uiCalendarConfig.calendars[calendar].fullCalendar('render');
                                    $('.fc-basicDay-button').hide();
                                    $('.fc-basicWeek-button').hide();
                                    $scope.$apply();
                                }
                            });
                        };
                        $scope.eventRender = function (event, element, view) {
                            $compile(element)($scope);
                        };
                        /* alert on eventClick */
                        $scope.onEventClick = function (pevent, jsEvent, view, p3, p4, p5) {
                            var start = new Date(pevent.start);
                            var duracion = $scope.step;
                            
                            // TODO: fix provisorio (+ 3hs)
                            start.setHours(start.getHours() + 3);
                            // if (window.location.href.indexOf("turnosportables") > 0) {
                            //     //var path = '/turnosportables/' + $scope.institucion._id + '/' + $scope.prestador.prestador_id +  '/reservarturno/' + start ;

                                 $rootScope.$broadcast('fechaTurnoSelected', start);
                            // } else {
                            //     var path = '/medicos/' + $scope.institucion._id + '/' + $scope.prestador.prestador_id + '/reservarTurno/' + start + '/' + duracion;
                            // }


                            //$location.path(path);
                        };
                        var openAltaTurno = function (turno) {
                        };

                        /* config object */
                        
                        // srvcPrestadores.getHorariosLibres($scope.institucion._id,
                        //     $scope.prestador.prestador_id,
                        //     $scope.step,
                        //     new Date().toISOString(),
                        //     new Date(end).toISOString()).then(function (horarios) {
                        //         console.log("horarios:",horarios);
                        //         var turnos = [];
                        //         angular.forEach(horarios, function (val, key) {
                        //             var evt = renderTurno(val);
                        //             turnos.push(evt);
                        //         });
                        //         callback(turnos);
                        //     }, function (err) {
                        //         toastr.error("Ha ocurrido un error al obtener la disponibilidad del prestador. Por favor intente nuevamente más tarde.", config.toastrErrorConfig);
                        //     });
                        $scope.getMondayOfCurrentWeek = function(d)
                        {
                            d = new Date(d);
                            var day = d.getDay();
                            return new Date(d.getFullYear(), d.getMonth(), d.getDate() + (day == 0?-6:1)-day );
                        }

                        $scope.uiConfig = {
                            calendar: {
                                events: function (start, end, timezone, callback) {
                                    var startDt = new Date(start).toISOString();
                                    var endDt = new Date(end).toISOString();
                                    srvcPrestadores.getHorariosLibres($scope.institucion._id,
                                        $scope.prestador.prestador_id,
                                        $scope.step,
                                        startDt,
                                        endDt).then(function (horarios) {
                                            if (horarios.length == 0 ){
                                                SweetAlert.swal({
                                                    title: "No hay turnos para la semana seleccionada!",
                                                    text: "No hay turnos desde el " + new Date(start).toLocaleDateString()  + " hasta el " + new Date(end).toLocaleDateString() + ". Por favor seleccione otra fecha.",
                                                    type: "error",
                                                    confirmButtonColor: "#4EC2B8",
                                                    confirmButtonText: "Aceptar",
                                                    closeOnConfirm: true
                                                },
                                                    function () {
                                                        
                                                    });
                                            }
                                           

                                            var turnos = [];
                                            angular.forEach(horarios, function (val, key) {
                                                var evt = renderTurno(val);
                                                turnos.push(evt);
                                            });
                                            //Posiciono el calendario en el primer espacio disponible.
                                            if(turnos[0] && $scope.initialLoad){
                                                $scope.initialLoad = false;
                                                uiCalendarConfig.calendars['calendario'].fullCalendar('gotoDate', new Date(turnos[0].start));
                                            }
                                            callback(turnos);
                                        }, function (err) {
                                            toastr.error("Ha ocurrido un error al obtener la disponibilidad del prestador. Por favor intente nuevamente más tarde.", config.toastrErrorConfig);
                                        });
                                },
                                height: 500,
                                editable: false,
                                // timezone: 'America/Argentina/Buenos_Aires',
                                timezone: 'UTC',
                                scrollTime: '{0}:00:00'.replace('{0}', new Date().getHours()),
                                header: {
                                    left: 'title',
                                    center: 'basicDay basicWeek',
                                    right: 'today prev,next'
                                }, 
                                slotDuration: $scope.prestador && $scope.prestador.datosAgenda ?
                                    '00:{00}:00'.replace('{00}', $scope.prestador.datosAgenda.duracionTurnoMin)
                                    : '00:15:00',
                                dayNames: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
                                dayNamesShort: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
                                monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
                                monthNamesShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
                                eventRender: $scope.eventRender,
                                allDaySlot: false,
                                eventClick: $scope.onEventClick,
                            }
                        };
                    };
                    $scope.$watch(function (scope) { return scope.prestador },
                        function () {
                            if (!$scope.prestador)
                                return;
                            $scope.step = $scope.prestador.datosAgenda ? ($scope.prestador.datosAgenda.duracionTurnoMin ? $scope.prestador.datosAgenda.duracionTurnoMin : 15) : 15;
                            $scope.eventSources = [];
                            $scope.calendarCtrl();
                            $scope.renderCalendar('calendario');
                        }
                    );
                    $scope.$watch(function (scope) { return scope.fechaTurno },
                        function () {
                            if (!$scope.fechaTurno)
                                return;
                            setTimeout(function () {
                                uiCalendarConfig.calendars['calendario'].fullCalendar('gotoDate', new Date($scope.fechaTurno));
                            });
                        }
                    );

                    //Punto de entrada
                    $scope.initialLoad = true;
                }
            }
        })
} ());