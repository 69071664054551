(function () {
    'use strict';

    angular.module('TodoTurnosApp')
        .controller('confirmarTurnoController', confirmarTurnoController)
        .filter('dateToISO', function () {
            return function (input) {
                if (input) {
                    return new Date(input).toISOString().replace('Z', '-03:00');
                }
            };
        });;

    confirmarTurnoController.$inject = [
        '$scope',
        '$routeParams',
        'srvcPrestadores',
        '$location',
        'srvcPacientes',
        'TodoTurnosAppUtils',
        'Upload', 
        '$timeout'
    ];

    function confirmarTurnoController($scope, $routeParams, srvcPrestadores, $location, srvcPacientes, utils, Upload, $timeout) {
        //Funciones
        $scope.uploadFiles = function(file, errFiles) {
            $scope.f = file;
            $scope.errFile = errFiles && errFiles[0];
            if (file) {
                file.upload = Upload.upload({

                    //url: 'https://localhost:3000/turnos/attachment',
                    url : 'https://be.todoturnos.com/turnos/attachment',

                    headers : {
                        'Content-Type': file.type
                    },
                    
                    method: 'PUT',
                    data: {
                        idTurno: idTurno,
                        file: file
                    }
                });
    
                file.upload.then(function (response) {
                    $timeout(function () {
                        file.result = response.data;
                    });
                }, function (response) {
                    if (response.status > 0)
                        $scope.errorMsg = response.status + ': ' + response.data;
                }, function (evt) {
                    file.progress = Math.min(100, parseInt(100.0 * 
                                             evt.loaded / evt.total));
                });
            }   
        }
        $scope.confirmar = function () {
            if(!$scope.turno.esVideollamada){
                $scope.turno.estado = "56a6ca4b2189b35862b6ee8a";
            }
            enviarConfirmacion();
        }

        $scope.cancelar = function () {
            if(confirm("¿Está seguro que desea cancelar el turno?")){
                $scope.turno.estado = "56a6ca4b2189b35862b6ee8c";
                enviarConfirmacion();
            }
        }

        var mostrarMensaje = function (mensaje) {
            $scope.faltaConfirmar = false;
            $scope.mensajeFinal = mensaje;
        }

        var enviarConfirmacion = function () {
            utils.audit($scope.turno);
            srvcPrestadores.saveConfirmacionTurno($scope.turno)
                .then(function (data) {
                    mostrarMensaje("¡Muchas gracias por su respuesta!")
                })
                .catch(function (err) {
                    if (err.mensaje) {
                        mostrarMensaje(err.mensaje)
                    } else {
                        mostrarMensaje("Hubo un problema al confirmar el turno, intente nuevamente más tarde.");
                    }
                })
        }
        //Punto de entrada
        $('header').css('display', 'none');
        $('body').css('padding-top', '0px');
        $('footer').css('display', 'none');

        $scope.comentario = "";
        $scope.faltaConfirmar = true;

        var idTurno = $routeParams.idTurno;
        srvcPrestadores.getConfirmacionTurno(idTurno)
            .then(function (res) {
                $scope.turno = res;
                if (new Date($scope.turno.fecha) < new Date() || ($scope.turno.estado != "56a6ca4b2189b35862b6ee8a" && $scope.turno.estado != "56a6ca4b2189b35862b6ee89")) {
                    mostrarMensaje("¡Oops! Este turno ya no puede ser confirmado.");
                }
            })
            .catch(function (err) {
                $scope.faltaConfirmar = false;
                if (err.mensaje) {
                    mostrarMensaje(err.mensaje);
                } else {
                    mostrarMensaje("Hubo un problema al obtener el turno, intente nuevamente más tarde.");
                }
            });

    };
})();