(function () {
    //Módulo principal de la aplicación
    angular.module('TodoTurnosApp')
        .config(function (lockProvider, $httpProvider, jwtInterceptorProvider, jwtOptionsProvider) {
            lockProvider.init({
                clientID: '8DvuZYr9W0QeNnKUMlZJNCwsVZJ9p3I6',
                domain: 'auth.TodoTurnos.com',
                options: {
                    configurationBaseUrl: 'https://cdn.auth0.com',
                    rememberLastLogin: false,
                    language: 'es',
                    theme: {
                        displayName: "",
                        logo: './public/images/logo.png',
                        primaryColor: '#44bfb8'
                    },
                    // autoclose: true,
                    auth: {
                        redirectUrl: window.location.origin,
                        responseType: 'token',
                        params: {
                            scope: 'openid name email'
                        }
                    },
                    languageDictionary: {
                        signUpTerms: "Acepto las <a href='https://todoturnos.com/resources/docs/Condiciones_de_uso_TodoTurnos.pdf' target='_blank'>condiciones de uso</a> y la <a href='https://todoturnos.com/resources/docs/Politica_de_Privacidad_TodoTurnos.pdf' target='_blank'>política de privacidad</a>."
                    }
                }

                // loginUrl: '/login'

            });
            jwtOptionsProvider.config({
                tokenGetter: function () {
                    return localStorage.getItem('token');
                },
                whiteListedDomains: ['profesional.todoturnos.com/', 'todoturnos.com', 'be.todoturnos.com', 'localhost', '104.196.156.149:3000', '104.196.156.149']
            });
            $httpProvider.interceptors.push('jwtInterceptor');
        })
        .run(function ($rootScope, authService, lock, jwtHelper, $location, TodoTurnosAppUtils, srvcSeguridad, TodoTurnosAppSession) {
            lock.interceptHash();
            authService.registerAuthenticationListener();

            //refresh or URL change
            $rootScope.$on('$locationChangeStart', function (event, next, current) {
                authService.checkAuthOnRefresh();
            });

            $rootScope.$on('$routeChangeStart', function (event, nextRoute, currentRoute) {
                var hasProfile = function () {
                    var prfl = srvcSeguridad.getPerfilUsuario();
                    if (prfl && prfl.profile) {
                        return (prfl.profile.metadata != null);
                    }
                    return null;
                };
                TodoTurnosAppSession.setParametrosSistema();
                TodoTurnosAppSession.setObrasSociales();
                var _hasProfile = hasProfile();
                var routeNext = TodoTurnosAppUtils.buildPathFromRoute(nextRoute);
                //guardo la ruta actual si el usuario no esta autenticado y la ruta requiere autenticacion
                if (nextRoute.requiresLogin && (!authService.isAuthenticated() || !_hasProfile)) {
                    TodoTurnosAppSession.setUltimaRuta(routeNext);
                    if (authService.isAuthenticated() && !_hasProfile) {
                        $location.path('/registroPaciente');
                    }
                    else if (!authService.isAuthenticated()) {
                        $location.path('/login');
                    }
                }
                else if (routeNext.indexOf('/login') == -1 && routeNext.indexOf('/registroPaciente') == -1) {
                   // TodoTurnosAppSession.setUltimaRuta(routeNext);
                }

            });
        });
} ());