(function () {
    angular.module('TodoTurnosApp')
        .service('srvcBusqueda', [
            '$http',
            '$q', 
            'TodoTurnosAppConfig',
            'TodoTurnosAppSession',
            'TodoTurnosAppUtils',
         function ($http, $q, config, session, utils) {
            
            
            //API
            var o = {            
                ///===================================================
                //idPrestador: identificador del prestador
                ///===================================================
                searchFor: function (fieldFree, fieldPlace,base,offset) {
                    var defer = $q.defer();
                    $http({ method: 'GET', url: config.urlRestServices + 'busqueda/fieldFree/' + fieldFree + '/fieldPlace/' + fieldPlace + '/base/' + base + '/offset/' + offset })
                    .then(function (response) {
                        defer.resolve(response.data);
                    }, function (err) {
                        defer.reject(err);
                    });
                    return defer.promise;
                },
                searchForCount: function (fieldFree, fieldPlace) {
                    var defer = $q.defer();
                    $http({ method: 'GET', url: config.urlRestServices + 'busqueda/fieldFree/' + fieldFree + '/fieldPlace/' + fieldPlace + '/count' })
                    .then(function (response) {
                        defer.resolve(response.data);
                    }, function (err) {
                        defer.reject(err);
                    });
                    return defer.promise;
                },

                getCacheItemsContainsValue:function(val, max){
                    var defer = $q.defer();
                    $http({ method: 'GET', url: config.urlRestServices + 'busqueda/cache/' + val + "/" + max  })
                    .then(function (response) {
                        defer.resolve(response.data);
                    }, function (err) {
                        defer.reject(err);
                    });
                    return defer.promise;
                }
            
            };
            return o;
        }]);
} ());