(function () {
    angular.module('TodoTurnosApp')
        .factory('mapsService', ['$http', 'TodoTurnosAppConfig', '$q',
            function ($http, config, $q) {
                var parseAddress = function (addr) {
                    var res = addr.split(" ");
                    var parametros = '';
                    for (i = 0; i < res.length; i++) {
                        parametros = parametros + res[i];
                        if (i != res.length - 1) {
                            parametros = parametros + '+';
                        }
                    }
                    return parametros;
                };
                var o = {
                    geoCode: function (institucion) {
                        var defer = $q.defer();
                        if (institucion.direccion && institucion.direccion.detalle) {
                            if (institucion.direccion.coord && institucion.direccion.coord.length > 0) {
                                defer.reject(institucion._id + ' ya fue geocodificada');
                                return defer.promise;
                            }
                            var addrParsed = parseAddress(institucion.direccion.detalle);
                            $http({ method: 'GET', url: config.googleMapsAPI + '&address=' + addrParsed })
                                .then(function (data) {
                                    institucion.direccion.parsed = true;
                                    if (!data.data.results[0]) {
                                        $http({ method: 'POST', url: config.urlRestServices + 'instituciones/' + institucion._id + '/updateDireccion', data: institucion.direccion })
                                        .then(function () {
                                        }, function (err) {
                                        });
                                        defer.reject(institucion._id + 'no pudo ser geocodificada');
                                    }
                                    else {
                                        $.each(data.data.results[0].address_components, function (i, v) {
                                            if (v.types[0] == "street_number") {
                                                institucion.direccion.altura = v.long_name;
                                            }
                                            if (v.types[0] == "route") {
                                                institucion.direccion.domicilio = v.long_name;
                                            }
                                            if (v.types[0] == "administrative_area_level_1") {
                                                institucion.direccion.provincia = v.short_name;
                                            }
                                            if (v.types[0] == "administrative_area_level_2") {
                                                institucion.direccion.ciudad = v.long_name;
                                            }
                                            if (v.types[0] == "locality" || v.types[1] == "sublocality") {
                                                institucion.direccion.barrio = v.long_name;
                                            }
                                            if (v.types[0] == "country") {
                                                institucion.direccion.pais = v.long_name;
                                            }
                                            if (v.types[0] == "postal_code") {
                                                institucion.direccion.cp = v.long_name;
                                            }
                                            if (v.types[0] == "postal_code_suffix") {
                                                institucion.direccion.cp_suffix = v.long_name;
                                            }
                                        })
                                        institucion.direccion.coord.push(data.data.results[0].geometry.location.lat);
                                        institucion.direccion.coord.push(data.data.results[0].geometry.location.lng);
                                        $http({ method: 'POST', url: config.urlRestServices + 'instituciones/' + institucion._id + '/updateDireccion', data: institucion.direccion })
                                        .then(function () {
                                            defer.resolve(institucion);
                                        }, function (err) {
                                            defer.reject(err);
                                        });
                                    }
                                    
                                }, function (err) {
                                    defer.reject(err);
                                });
                        }
                        else {
                            defer.reject(institucion._id + '  no tiene detalle');
                        }
                        return defer.promise;
                    }
                };
                return o;
            }]);

} ());