(function () {
    angular.module('TodoTurnosApp').controller('LogoutCtrl', ['$scope',
     'authService', 
     '$location',
      '$rootScope',
        function ($scope, authService, $location, $rootScope) {
            var cleansession = function () {
                localStorage.removeItem('profile');
                localStorage.removeItem('token');
                localStorage.removeItem('accessToken');
                localStorage.removeItem('obrasSociales');
                localStorage.removeItem('parametrosSistema');
                localStorage.removeItem('turnoSeleccionado');
                localStorage.removeItem('ultimaRuta');
                localStorage.removeItem('prestadorSeleccionado');
                localStorage.removeItem('institucionSeleccionada');

            };
            $scope.logout = function () {
                authService.unAuthenticate();
                cleansession();
                $rootScope.$emit('logout', "seteado");
                $location.path('/');
            };
            $scope.logout();
        }]);
} ());